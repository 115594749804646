import {
  VerticalBarGraph,
  DoughnutGraph,
  Loader,
  HorizontalBarGraph,
} from "../components";
import { useState, useEffect } from "react";
import { getDocumentsCount, getEventsCount } from "../config/firestore";
import eventsData from "../data/events.json";
const Dashboard = () => {
  const competitionsJson = eventsData.data;
  const [loading, setLoading] = useState(true);
  const [roadtrips, setRoadtrips] = useState({});
  const [competitions, setCompetitions] = useState({});
  const [bar, setBar] = useState({});
  const fetchData = async () => {
    setLoading(true);
    try {
      let synchro = await getDocumentsCount("synchronicity24");
      let comickaun = await getDocumentsCount("comickaun24");
      let djwar = await getDocumentsCount("djwar24");
      let beatboxing = await getDocumentsCount("beatboxing24");
      let rap = await getDocumentsCount("rap24");
      let junoon = await getDocumentsCount("junoon24");
      let roadtripsLabels = [
        "Synchronicity",
        "Comickaun",
        "DJ War",
        "Beatboxing",
        "Rap",
        "Junoon",
      ];
      let roadtripsData = [synchro, comickaun, djwar, beatboxing, rap, junoon];
      const data = {
        labels: roadtripsLabels,
        datasets: [
          {
            label: "Registrations",
            data: roadtripsData,
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.5)",
              "rgba(255, 206, 86, 0.5)",
              "rgba(75, 192, 192, 0.5)",
              "rgba(153, 102, 255, 0.5)",
              "rgba(255, 159, 128, 0.5)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 128, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };
      setRoadtrips(data);
      // Individual Competition Counts
      let competitionsLabels = [];
      let competitionsCount = [];
      for (let i = 0; i < competitionsJson.length; i++) {
        let comp = competitionsJson[i].comp;
        let count = await getEventsCount("eventsUsers2024", comp);
        competitionsLabels.push(comp);
        competitionsCount.push(count);
      }
      let competitionsData = {
        labels: competitionsLabels,
        datasets: [
          {
            label: "Registrations",
            data: competitionsCount,
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.5)",
              "rgba(255, 206, 86, 0.5)",
              "rgba(75, 192, 192, 0.5)",
              "rgba(153, 102, 255, 0.5)",
              "rgba(255, 159, 128, 0.5)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 128, 1)",
            ],
          },
        ],
      };
      setCompetitions(competitionsData);
      // Bar Data
      let events = await getDocumentsCount("eventsUsers2024");
      let ca = await getDocumentsCount("CAs24");
      let roadtrips = synchro + comickaun + djwar + beatboxing + rap + junoon;
      let barLabels = [
        "Events Registrations",
        "CA Registrations",
        "Roadtrips Registrations",
      ];
      let barData = [events, ca, roadtrips];
      let barGraphdata = {
        labels: barLabels,
        datasets: [
          {
            label: "Registrations",
            data: barData,
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.5)",
              "rgba(255, 206, 86, 0.5)",
              "rgba(75, 192, 192, 0.5)",
              "rgba(153, 102, 255, 0.5)",
              "rgba(255, 159, 128, 0.5)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 128, 1)",
            ],
          },
        ],
      };
      setBar(barGraphdata);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div
      className="flex items-center justify-center flex-wrap overflow-auto  p-5"
      style={{ width: "calc(100vw - 80px)", height: "calc(100vh - 70px)" }}
    >
      <VerticalBarGraph
        data={competitions}
        options={{
          elements: {
            bar: {
              borderWidth: 1,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: "All Competitions Registrations",
            },
          },
        }}
      />
      <HorizontalBarGraph
        data={bar}
        width="130px"
        height="100px"
        options={{
          indexAxis: "y",
          elements: {
            bar: {
              borderWidth: 1,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: "All Registrations",
            },
          },
        }}
      />
      <DoughnutGraph
        data={roadtrips}
        height="70px"
        options={{
          plugins: {
            title: {
              display: true,
              text: "Roadtrips Registrations",
            },
          },
        }}
      />
    </div>
  );
};
export default Dashboard;
