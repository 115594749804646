import { useState, useEffect } from "react";
import {
  getAllDocs,
  getDocumentsCount,
  getFirstData,
  getNextData,
  deleteData,
} from "../config/firestore";
import eventsData from "../data/events.json";
import { Loader, ShowToast, ShowAlert } from "../components";
import { downloadExcel } from "../utils/exportImport";
const EventsUsersRegistrations = () => {
  const [regs, setRegs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [sortBy, setSortBy] = useState("dateTime");
  const [order, setOrder] = useState("desc");
  // const [searchBy, setSearchBy] = useState("dateTime");
  const [searchText, setSearchText] = useState("");
  const [lastDoc, setLastDoc] = useState("");
  const data = eventsData.data;
  const getAllRegs = async () => {
    setLoading(true);
    let { last, data } = await getFirstData(
      "eventsUsers2024",
      sortBy,
      order,
      sortBy,
      searchText
    );
    let count = await getDocumentsCount("eventsUsers2024");
    if (data != null) {
      setRegs(data);
      setCurrentCount(data.length);
      setTotalCount(count);
      setLastDoc(last);
    }
    setLoading(false);
  };

  const getNext = async () => {
    if (lastDoc != null) {
      let { last, data } = await getNextData(
        "eventsUsers2024",
        sortBy,
        order,
        lastDoc,
        sortBy,
        searchText
      );
      if (data != null) {
        setRegs([...regs, ...data]);
        setLastDoc(last);
        setCurrentCount(currentCount + data.length);
      }
    }
  };

  useEffect(() => {
    // editData();
    if (sortBy === "competitions") {
      setSearchText("JITTERBUG");
    } else {
      getAllRegs();
    }
  }, [sortBy, order]);

  useEffect(() => {
    if (sortBy === "competitions") {
      getAllRegs();
    }
  }, [searchText]);

  return (
    <div>
      <div className="bg-white fixed w-full left-0 pl-20 z-10">
        <div className="flex items-end mx-3 mb-2 justify-between">
          <div className="flex flex-col justify-end">
            <h2 className="text-xl font-bold my-2">Events Registrations</h2>
            <div className="flex items-end">
              <button
                className="btn btn-neutral mr-2"
                onClick={async () => {
                  try {
                    ShowToast(
                      "Preparing Excel Sheet, Downlaod will start in few minutes,Please Wait !"
                    );
                    let users = await getAllDocs("eventsUsers2024");
                    let rows = [];
                    for (let i = 0; i < users.length; i++) {
                      let user = users[i].data;
                      let rawComps = user.userComps || [];
                      let comps = [];
                      let links = [];
                      for (let comp of rawComps) {
                        comps.push(comp.competition);
                        links.push(comp.link);
                      }
                      let singleRow = {
                        "Sr. No.": i + 1,
                        "Antaragni Id": user.id,
                        Name: user.name,
                        "Phone Number": user.phone,
                        "Whatsapp Number": user.whatsapp,
                        "Email Id": user.email,
                        College: user.college,
                        "City Of College": user.collegeCity,
                        "Year Of Study": user.year,
                        Address: user.address,
                        Gender: user.gender,
                        "Facebook Profile": user.fb,
                        "Instagram Profile": user.insta,
                        "Team Id": user.teamId,
                        "Team Name": user.teamName,
                        "Is Team Leader ?": user.isTeamLeader,
                        Competitions: comps.toString(),
                        "Performance Links": links.toString(),
                        "Date-Time": user.dateTime,
                      };
                      rows.push(singleRow);
                    }
                    downloadExcel("EventsUsersRegistrations", rows);
                  } catch (e) {
                    ShowToast(
                      "Something Went Wrong !!\n Please Try Again Later"
                    );
                    console.log(e);
                  }
                }}
              >
                Save as Excel
              </button>
              <label className=" flex items-center gap-2 font-semibold mx-2">
                Entries: {currentCount}&nbsp;/&nbsp;{totalCount}
                <button
                  onClick={() => {
                    getNext();
                  }}
                  className="btn"
                >
                  Show More
                </button>
              </label>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-end">
            <label className="form-control w-full max-w-40 mx-2">
              <div className="label">
                <span className="label-text">Sort by</span>
              </div>
              <select
                id="orderBy"
                value={sortBy}
                onChange={(e) => {
                  setSearchText("");
                  setSortBy(e.target.value);
                }}
                className="select select-bordered w-full max-w-40"
              >
                <option value={"dateTime"}>By Date-Time</option>
                <option value={"id"}>By Id</option>
                <option value={"name"}>By Name</option>
                <option value={"teamId"}>By Team Id</option>
                <option value={"teamName"}>By Team Name</option>
                <option value={"gender"}>By Gender</option>
                <option value={"college"}>By College</option>
                <option value={"year"}>By Year of Study</option>
                <option value={"collegeCity"}>By College City</option>
                <option value={"competitions"}>By Competition</option>
              </select>
            </label>
            {sortBy !== "competitions" && (
              <label className="form-control w-full max-w-40 mx-2">
                <div className="label">
                  <span className="label-text">Order by</span>
                </div>
                <select
                  value={order}
                  onChange={(e) => {
                    setOrder(e.target.value);
                  }}
                  className="select select-bordered w-full max-w-40"
                >
                  <option value={"asc"}>Ascending</option>
                  <option value={"desc"}>Descending</option>
                </select>
              </label>
            )}
            {sortBy === "competitions" ? (
              <select
                value={searchText}
                onChange={(e) => {
                  try {
                    setSearchText(e.target.value);
                  } catch (e) {
                    console.log(e);
                  }
                }}
                className="select select-bordered w-full max-w-40"
              >
                {data.map((e) => (
                  <option value={e.comp}>{e.comp}</option>
                ))}
              </select>
            ) : (
              <>
                <label className="form-control w-full max-w-52 mx-2">
                  <div className="label">
                    <span className="label-text">Search</span>
                  </div>
                  <input
                    id="search"
                    value={searchText}
                    type="text"
                    className="input input-bordered w-full max-w-xs"
                    onChange={(e) =>
                      setSearchText(e.target.value.toUpperCase())
                    }
                    placeholder={`Search by ${sortBy}`}
                  />
                </label>
                <button
                  onClick={() => {
                    getAllRegs();
                  }}
                  className="btn"
                >
                  Search
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="overflow-x-auto pt-28">
          <table className="table">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Antaragni Id</th>
                <th>Name</th>
                <th>Email Id</th>
                <th>Phone</th>
                <th>Whatsapp</th>
                <th>Team Id</th>
                <th>Team Name</th>
                <th>Is Team Leader ?</th>
                <th>Gender</th>
                <th>College</th>
                <th>City of College</th>
                <th>Year of Study</th>
                <th>Address</th>
                <th>Facebook</th>
                <th>Instagram</th>
                <th>Competitions</th>
                <th>Performance Links</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {regs.map((reg, index) => {
                return (
                  <UserEventRow
                    reg={reg}
                    index={index}
                    getAllRegs={getAllRegs}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const UserEventRow = ({ reg, index, getAllRegs }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{reg.data.id}</td>
      <td>{reg.data.name}</td>
      <td>
        <a href={`mailto:${reg.data.email}`}>{reg.data.email}</a>
      </td>
      <td>{reg.data.phone}</td>
      <td>{reg.data.whatsapp}</td>
      <td>{reg.data.teamId}</td>
      <td>{reg.data.teamName}</td>
      <td>{reg.data.isTeamLeader}</td>
      <td>{reg.data.gender}</td>
      <td className="overflow">
        <div>{reg.data.college}</div>
      </td>
      <td className="overflow">
        <div>{reg.data.collegeCity}</div>
      </td>
      <td>{reg.data.year}</td>
      <td className="overflow">
        <div>{reg.data.address}</div>
      </td>
      <td className="overflow">
        <a href={reg.data.fb}>
          <a href={reg.data.fb}>{reg.data.fb}</a>
        </a>
      </td>
      <td className="overflow">
        <a href={reg.data.insta}>
          <a href={reg.data.insta}>{reg.data.insta}</a>
        </a>
      </td>
      <td>
        <div style={{ height: "100%", maxHeight: "100px", overflow: "auto" }}>
          {reg.data.userComps &&
            reg.data.userComps.map((comp, index) => {
              return `${comp.competition}, `;
            })}
        </div>
      </td>
      <td className="link">
        <ul
          style={{
            width: "200px",
            height: "100%",
            maxHeight: "100px",
            overflow: "auto",
          }}
        >
          {reg.data.userComps &&
            reg.data.userComps.map((comp, index) => {
              return (
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "none",
                    width: "100%",
                    overflow: "auto",
                  }}
                >
                  <a href={comp.link}>{comp.link}</a>
                </li>
              );
            })}
        </ul>
      </td>
      <td>
        <div style={{ display: "flex" }}>
          <button
            className="btn btn-sm btn-neutral"
            onClick={async (e) => {
              ShowAlert(
                "Delete User",
                "Are you sure you want to delete this user !",
                async () => {
                  await deleteData("eventsUsers2024", reg.uid);
                  await getAllRegs();
                }
              );
            }}
          >
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};

export default EventsUsersRegistrations;
