import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Loader, ShowAlert } from "../components";
import { getSingleDoc, updateData } from "../config/firestore";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { uploadFile, deleteFile } from "../config/firebaseStorage";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { Timestamp } from "firebase/firestore";

import {
  convertToDateTimeLocalString,
  importExcel,
  importJson,
  downloadJson,
  convertExcelJsonToJson,
  convertJsonToExcelJson,
} from "../utils";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
// import "../css/custom-easymde.module.css"; 
const ContentNew = () => {
  let { contentId } = useParams();
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [headers, setHeaders] = useState([]);
  const [columnName, setColumnName] = useState("");
  const [type, setType] = useState("STRING");
  const [json, setJson] = useState([]);
  const [excelJson, setExcelJson] = useState([]);
  const fetchContent = async () => {
    setLoading(true);
    let doc = await getSingleDoc("WebContentsNew", contentId);
    if (doc) {
      setContent(doc.data);
      if (doc.data && doc.data.length > 0) {
        let entry = doc.data[0];
        let heads = Object.keys(entry);
        let headers = [];
        for (let head of heads) {
          let type = entry[head].type;
          headers.push({ type: type, name: head });
        }
        setHeaders(headers);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchContent();
  }, []);

  const updateContent = (data) => {
    try {
      setContent(data);
      if (data && data.length > 0) {
        let entry = data[0];
        let heads = Object.keys(entry);
        let headers = [];
        for (let head of heads) {
          let type = entry[head].type;
          headers.push({ type: type, name: head });
        }
        setHeaders(headers);
      }
    } catch (e) {
      console.log(e);
      ShowAlert("Error in Updating Content");
    }
  };

  const editContent = async (id, head, value) => {
    let data = [...content];
    data = data.map(function (object) {
      if (object.Id === id) {
        object[head].content = value;
      }
      return object;
    });
    await updateData("WebContentsNew", contentId, { data });
    updateContent(data);
  };

  const alphanumeric_unique = () => {
    return Math.random()
      .toString(36)
      .split("")
      .filter(function (value, index, self) {
        return self.indexOf(value) === index;
      })
      .join("")
      .substr(2, 8);
  };

  const insertRow = async () => {
    let id = alphanumeric_unique();
    let newObject = {};
    for (let i = 0; i < headers.length; i++) {
      newObject[headers[i].name] = initialField(headers[i].type);
    }
    newObject["Id"] = id;
    let data = [...content];
    data.push(newObject);
    await updateData("WebContentsNew", contentId, { data: data });
    updateContent(data);
  };

  const insertColumn = async (name, type) => {
    if (name === "" || type === "") {
      return;
    }
    let data = [...content];
    for (let i = 0; i < data.length; i++) {
      data[i][name] = initialField(type);
    }
    await updateData("WebContentsNew", contentId, { data: data });
    updateContent(data);
  };

  const initialField = (targetType) => {
    switch (targetType) {
      case "STRING":
        return {
          type: "STRING",
          content: "",
        };
      case "NUMBER":
        return {
          type: "NUMBER",
          content: 0,
        };
      case "BOOLEAN":
        return {
          type: "BOOLEAN",
          content: false,
        };
      case "FILE":
        return {
          type: "FILE",
          content: {
            ref: "",
            url: "",
          },
        };
      case "MD":
        return {
          type: "MD",
          content: "",
        };
      case "JSON":
        return {
          type: "JSON",
          content: {},
        };
      case "TIMESTAMP":
        return {
          type: "TIMESTAMP",
          content: Timestamp.fromDate(new Date()),
        };
      default:
        break;
    }
  };

  const deleteRow = async (id) => {
    let data = [...content];
    data = data.filter((e) => {
      return e.Id !== id;
    });
    await updateData("WebContentsNew", contentId, { data });
    updateContent(data);
  };

  const deleteColumn = async (head) => {
    let data = [...content];
    for (let i = 0; i < data.length; i++) {
      delete data[i][head];
    }
    await updateData("WebContentsNew", contentId, { data: data });
    updateContent(data);
  };

  const contentStyle = {
    background: "white",
    borderRadius: "16px",
    boxShadow: " 0 4px 30px rgba(0, 0, 0, 0.1)",
    border: "2px solid var(--primaryColor)",
    height:"70vh",
    position:"absolute",
    top:"25vh",
    left:"25vw",
    overflow:"auto"
  };
  const updateJSONData = async () => {
    try {
      await updateData("WebContentsNew", contentId, { data: json });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (json.length > 0) {
      updateJSONData();
      updateContent(json);
    }
  }, [json]);

  useEffect(() => {
    if (excelJson.length > 0) {
      let json = convertExcelJsonToJson(excelJson);
      setJson(json);
    }
  }, [excelJson]);

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="bg-white fixed w-full left-0 pl-20 z-10">
        <div className="mx-3">
          <h2 className="font-bold text-lg">Edit Web Content</h2>
          <button onClick={insertRow} className="btn btn-neutral m-2 ml-0">
            Insert New Row
          </button>
          <input
            type="text"
            className="input input-bordered w-full max-w-52 m-2"
            placeholder="Column Name"
            onChange={(e) => {
              setColumnName(e.target.value);
            }}
          ></input>
          <select
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
            className="select select-bordered w-full max-w-52 m-2"
          >
            <option value="STRING">Text</option>
            <option value="MD">Long Text</option>
            <option value="JSON">JSON</option>
            <option value="NUMBER">Number</option>
            <option value="BOOLEAN">Boolean</option>
            <option value="TIMESTAMP">Timestamp</option>
            <option value="FILE">File</option>
          </select>
          <button
            onClick={() => {
              insertColumn(columnName, type);
            }}
            className="btn btn-neutral m-2"
          >
            Insert New Column
          </button>
          <Popup
            trigger={
              <span>
                <button className="btn m-2">Export Data</button>
              </span>
            }
            modal
            {...{ contentStyle }}
          >
            <div className="flex flex-col items-center">
              <button
                onClick={() => {
                  convertJsonToExcelJson(contentId, content);
                }}
                className="btn btn-neutral m-2"
              >
                Export to Excel
              </button>
              <button
                onClick={() => {
                  downloadJson(contentId, content);
                }}
                className="btn btn-neutral m-2"
              >
                Export to JSON
              </button>
            </div>
          </Popup>
          <Popup
            trigger={
              <span>
                <button className="btn m-2">Import Data</button>
              </span>
            }
            modal
            {...{ contentStyle }}
          >
            <div className="flex flex-col items-center">
              <label className="form-control w-full max-w-xs m-2">
                <div className="label">
                  <span className="label-text">Import from Excel</span>
                  <span className="label-text-alt">
                    Must Contain column "Id"
                  </span>
                </div>
                <input
                  type="file"
                  onChange={(e) => {
                    importExcel(e, setExcelJson);
                  }}
                  className="file-input file-input-bordered w-full max-w-xs"
                />
              </label>
              <label className="form-control w-full max-w-xs m-2">
                <div className="label">
                  <span className="label-text">Import from JSON</span>
                  <span className="label-text-alt">Dev Exported JSON Only</span>
                </div>
                <input
                  type="file"
                  onChange={(e) => {
                    importJson(e, setJson);
                  }}
                  className="file-input file-input-bordered w-full max-w-xs"
                />
              </label>
            </div>
          </Popup>
        </div>
      </div>
      <div className="overflow-x-auto pt-24">
        <table className="table">
          <thead>
            <tr>
              <th>Sr. No.</th>
              {headers.map((head) =>
                head.name === "Id" ? (
                  <></>
                ) : (
                  <th>
                    <div className="flex justify-center">
                      <span>{head.name}</span>
                      <div className="mx-1">
                        <MdDelete
                          size={18}
                          color="var(--primaryColor)"
                          onClick={() => {
                            ShowAlert(
                              "Delete Column",
                              "Are you sure you want to delete this column !",
                              async () => {
                                content.map(async (cont) => {
                                  if (head.type === "FILE") {
                                    let prevFile = cont[head.name].content;
                                    if (
                                      prevFile != null &&
                                      prevFile.ref !== ""
                                    ) {
                                      deleteFile(prevFile.ref);
                                    }
                                  }
                                });
                                deleteColumn(head.name);
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                  </th>
                )
              )}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {content.map((cont, index) => (
              <tr>
                <td>{index + 1}</td>
                {headers.map((head) =>
                  head.name === "Id" ? (
                    <></>
                  ) : (
                    <td>
                      {head.type === "BOOLEAN" ? (
                        <input
                          checked={cont[head.name].content}
                          className="checkbox"
                          type="checkbox"
                          onChange={(event) => {
                            editContent(
                              cont.Id,
                              head.name,
                              event.target.checked
                            );
                          }}
                        ></input>
                      ) : head.type === "STRING" ? (
                        <InputField
                          type={"STRING"}
                          cont={cont}
                          head={head}
                          editContent={editContent}
                        />
                      ) : head.type === "NUMBER" ? (
                        <InputField
                          type={"NUMBER"}
                          cont={cont}
                          head={head}
                          editContent={editContent}
                        />
                      ) : head.type === "JSON" ? (
                        <InputField
                          type={"JSON"}
                          cont={cont}
                          head={head}
                          editContent={editContent}
                        />
                      ) : head.type === "FILE" ? (
                        <div className="flex flex-col justify-start items-center">
                          <input
                            type="file"
                            id="input-file"
                            className="file-input file-input-bordered w-full max-w-xs min-w-52"
                            onChange={async (event) => {
                              let file = event.target.files[0];
                              let path = `/${contentId}/${file.name}-${Date.now().toString()}`;
                              let url = await uploadFile(
                                path,
                                event.target.files[0]
                              );
                              let prevFile = cont[head.name].content;
                              editContent(cont.Id, head.name, {
                                ref: path,
                                url,
                              });
                              if (prevFile != null && prevFile.ref !== "") {
                                deleteFile(prevFile.ref);
                              }
                            }}
                          ></input>
                          {cont[head.name].content.url === null ||
                          cont[head.name].content.url === undefined ||
                          cont[head.name].content.url === "" ||
                          cont[head.name].content.url === " " ? (
                            <span style={{ color: "red" }}>No File Found</span>
                          ) : (
                            <a
                              href={cont[head.name].content.url}
                              target="_blank"
                              rel="noreferrer"
                              alt="file"
                            >
                              View
                            </a>
                          )}
                        </div>
                      ) : head.type === "MD" ? (
                        <Popup
                          trigger={
                            <span>
                              <button className="btn">Text Editor</button>
                            </span>
                          }
                          modal
                          {...{ contentStyle }}
                        >
                          <TextEditor
                            content={cont}
                            head={head}
                            editContent={editContent}
                          />
                        </Popup>
                      ) : head.type === "TIMESTAMP" ? (
                        <InputTimestamp
                          type={"TIMESTAMP"}
                          cont={cont}
                          head={head}
                          editContent={editContent}
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                  )
                )}
                <td>
                  <div className="flex justify-evenly">
                    <AiOutlineArrowUp
                      size={30}
                      color="var(--primaryColor)"
                      onClick={async () => {
                        let newContent = [...content];
                        let index = newContent.findIndex((e) => {
                          return e.Id === cont.Id;
                        });
                        let preIndex = index - 1;
                        if (preIndex >= 0) {
                          let preValue = newContent[preIndex];
                          newContent[preIndex] = newContent[index];
                          newContent[index] = preValue;
                        }
                        await updateData("WebContentsNew", contentId, {
                          data: newContent,
                        });
                        setContent(newContent);
                      }}
                    />
                    <AiOutlineArrowDown
                      size={30}
                      color="var(--primaryColor)"
                      onClick={async () => {
                        let newContent = [...content];
                        let index = newContent.findIndex((e) => {
                          return e.Id === cont.Id;
                        });
                        let postIndex = index + 1;
                        if (postIndex < newContent.length) {
                          let postValue = newContent[postIndex];
                          newContent[postIndex] = newContent[index];
                          newContent[index] = postValue;
                        }
                        await updateData("WebContentsNew", contentId, {
                          data: newContent,
                        });
                        setContent(newContent);
                      }}
                    />
                    <MdDelete
                      size={30}
                      color="var(--primaryColor)"
                      onClick={() => {
                        ShowAlert(
                          "Delete Row",
                          "Are you sure you want to delete this row !",
                          async () => {
                            headers.map(async (head) => {
                              if (head.type === "FILE") {
                                let prevFile = cont[head.name].content;
                                if (prevFile != null && prevFile.ref !== "") {
                                  deleteFile(prevFile.ref);
                                }
                              }
                            });
                            await deleteRow(cont.Id);
                          }
                        );
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const InputField = ({ type, cont, head, editContent }) => {
  const [initValue, setInitValue] = useState();

  useEffect(() => {
    if (type === "JSON") {
      setInitValue(JSON.stringify(cont[head.name].content));
    } else {
      setInitValue(cont[head.name].content);
    }
  });

  return type === "NUMBER" ? (
    <input
      type="number"
      defaultValue={initValue}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          editContent(cont.Id, head.name, Number(event.target.value));
        }
      }}
      className="input input-bordered w-full max-w-xs"
    ></input>
  ) : type === "JSON" ? (
    <textarea
      defaultValue={initValue}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          editContent(cont.Id, head.name, JSON.parse(event.target.value));
        }
      }}
      className="input input-bordered w-full max-w-xs min-w-52"
    ></textarea>
  ) : (
    <textarea
      defaultValue={initValue}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          editContent(cont.Id, head.name, event.target.value);
        }
      }}
      className="input input-bordered w-full max-w-xs min-w-52"
    ></textarea>
  );
};

const InputTimestamp = ({ type, cont, head, editContent }) => {
  const [initValue, setInitValue] = useState();

  useEffect(() => {
    setInitValue(
      convertToDateTimeLocalString(cont[head.name].content.toDate())
    );
  });

  return (
    <input
      type="datetime-local"
      className="input input-bordered w-full max-w-xs min-w-52"
      defaultValue={initValue}
      onChange={(e) => {
        editContent(
          cont.Id,
          head.name,
          Timestamp.fromDate(new Date(e.target.value))
        );
      }}
    ></input>
  );
};

export default ContentNew;

const TextEditor = ({ content, head, editContent }) => {
  const [value, setValue] = useState(content[head.name].content);

  const onChange = (e) => {
    setValue(e);
  };

  const onSave = () => {
    editContent(content.Id, head.name, value);
  };

  return (
    <div >
      <SimpleMDE value={value} onChange={onChange} />
      <button className="btn btn-neutral" onClick={onSave}>
        Save
      </button>
    </div>
  );
};
