import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";

const ShowAlert = (title, description, confirm) => {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          className="bg-white p-5  rounded-xl "
          style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
        >
          <h1 className="text-lg font-bold">{title}</h1>
          <p className="font-normal">{description}</p>
          <div className="w-full flex justify-evenly mt-3">
            <button onClick={onClose} className="btn">
              No
            </button>
            <button
              onClick={() => {
                confirm();
                onClose();
              }}
              className="btn btn-neutral"
            >
              Yes
            </button>
          </div>
        </div>
      );
    },
  });
};

const ShowToast = (title) => {
  return toast(title);
};

export { ShowAlert, ShowToast };
