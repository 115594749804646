import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  doc,
  getDocs,
  updateDoc,
  getDoc,
  deleteDoc,
  startAfter,
  endAt,
  getCountFromServer,
  query,
  orderBy,
  where,
  limit,
} from "firebase/firestore";
import firebaseApp from "./firebaseConfig";

const db = getFirestore(firebaseApp);

const addData = async (collectionName, data) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), data);
    return;
  } catch (error) {
    console.log("Error in adding data: ", error);
  }
};

const setData = async (collectionName, id, data) => {
  try {
    await setDoc(doc(db, collectionName, id), data);
  } catch (error) {
    console.log("Error in setting data: ", error);
  }
};

const getAllDocs = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    let data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        uid: doc.id,
        data: doc.data(),
      });
    });
    return data;
  } catch (error) {
    console.log("Error in fetching data: ", error);
  }
};

const getSingleDoc = async (collectionName, docId) => {
  try {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (error) {
    console.log("Error ", error);
  }
};

const updateData = async (collectionName, docId, data) => {
  try {
    await updateDoc(doc(db, collectionName, docId), data);
  } catch (error) {
    console.log("Error in updating data: ", error);
  }
};

const deleteData = async (collectionName, docId) => {
  try {
    await deleteDoc(doc(db, collectionName, docId));
  } catch (error) {
    console.log("Error in deleting data: ", error);
  }
};

const getFirstData = async (
  collectionName,
  orderby,
  order,
  searchBy,
  searchText
) => {
  try {
    let q;
    if (searchBy === "competitions") {
      q = query(
        collection(db, collectionName),
        where(searchBy, "array-contains", searchText),
        limit(100)
      );
    } else {
      q = query(
        collection(db, collectionName),
        orderBy(orderby, order),
        where(searchBy, ">=", searchText),
        limit(100)
      );
    }
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        uid: doc.id,
        data: doc.data(),
      });
    });
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];
    return { data, last };
  } catch (error) {
    console.log("Error in geting first data: ", error);
  }
};

const getNextData = async (
  collectionName,
  orderby,
  order,
  lastDoc,
  searchBy,
  searchText
) => {
  try {
    let q;
    if (searchBy === "competitions") {
      q = query(
        collection(db, collectionName),
        startAfter(lastDoc),
        where(searchBy, "array-contains", searchText),
        limit(100)
      );
    } else {
      q = query(
        collection(db, collectionName),
        orderBy(orderby, order),
        startAfter(lastDoc),
        where(searchBy, ">=", searchText),
        limit(100)
      );
    }
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        uid: doc.id,
        data: doc.data(),
      });
    });
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];
    return { data, last };
  } catch (error) {
    console.log("Error in geting next data: ", error);
  }
};

const getDocumentsCount = async (collectionName) => {
  try {
    const snapshot = await getCountFromServer(collection(db, collectionName));
    return snapshot.data().count;
  } catch (error) {
    console.log("Error in getting count: ", error);
  }
};

const getEventsCount = async (collectionName, competition) => {
  try {
    const coll = collection(db, collectionName);
    const q = query(coll, where("competitions", "array-contains", competition));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  } catch (e) {
    console.log(e);
  }
};

const queryData = async (collectionName, searchBy, searchValue) => {
  try {
    let q = query(
      collection(db, collectionName),
      where(searchBy, "==", searchValue)
    );
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        uid: doc.id,
        data: doc.data(),
      });
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export {
  addData,
  setData,
  getAllDocs,
  updateData,
  getSingleDoc,
  deleteData,
  getDocumentsCount,
  getFirstData,
  getNextData,
  queryData,
  getEventsCount,
};
export default db;
