import styles from "../css/webContents.module.css";
import {
  ContentCard,
  ShowAlert,
  Loader,
  Button,
  ShowToast,
} from "../components";
import { useState, useEffect } from "react";
import { getAllDocs, deleteData, setData } from "../config/firestore";
import { useAuth } from "../hooks";
import { deleteFile, deleteFolder } from "../config/firebaseStorage";
const WebContents = () => {
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState([]);
  const [newContent, setNewContent] = useState("");
  const auth = useAuth();
  const fetchContents = async () => {
    setLoading(true);
    let data = await getAllDocs("WebContents");
    if (data != null) {
      setContents(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchContents();
  }, []);

  const deleteContent = async (contentName) => {
    await deleteData("WebContents", contentName);
    fetchContents();
    ShowToast("Content Deleted Successfully");
  };

  const AddNewContent = async () => {
    if (newContent !== "") {
      await setData("WebContents", newContent, { data: [] });
      setNewContent("");
      ShowToast("Content Created Successfully");
      fetchContents();
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.webContents}>
      {contents.map((content) => {
        return (
          <ContentCard
            content={content}
            deleteContent={() => {
              if (auth.user.details.accessLevels.includes("admin")) {
                ShowAlert(
                  "Delete Content",
                  "Are you sure you want to delete this content !",
                  async () => {
                    deleteFolder(`/${content.uid}/`);
                    deleteContent(content.uid);
                  }
                );
              }
            }}
            isPermitted={auth.user.details.accessLevels.includes("admin")}
          />
        );
      })}
      <div className={styles.addBtn}>
        <input
          placeholder="Content Name"
          value={newContent}
          onChange={(e) => {
            setNewContent(e.target.value);
          }}
        ></input>
        <Button
          text="Add Content"
          onClick={() => {
            AddNewContent();
          }}
        />
      </div>
    </div>
  );
};

export default WebContents;
