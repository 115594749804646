import { useState, useEffect } from "react";
import {
  getDocumentsCount,
  getFirstData,
  getNextData,
} from "../config/firestore";
import { Loader } from "../components";
import { exportToExcel } from "../utils";
const Synchronicity = () => {
  const [regs, setRegs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [sortBy, setSortBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [searchBy, setSearchBy] = useState("name");
  const [searchText, setSearchText] = useState("");
  const [lastDoc, setLastDoc] = useState("");

  const getAllRegs = async () => {
    setLoading(true);
    let { last, data } = await getFirstData(
      "synchronicity24",
      sortBy,
      order,
      searchBy,
      searchText
    );
    let count = await getDocumentsCount("synchronicity24");
    if (data != null) {
      setRegs(data);
      setCurrentCount(data.length);
      setTotalCount(count);
      setLastDoc(last);
    }
    setLoading(false);
  };

  const getNext = async () => {
    if (lastDoc != null) {
      let { last, data } = await getNextData(
        "synchronicity24",
        sortBy,
        order,
        lastDoc,
        searchBy,
        searchText
      );
      if (data != null) {
        setRegs([...regs, ...data]);
        setLastDoc(last);
        setCurrentCount(currentCount + data.length);
      }
    }
  };

  useEffect(() => {
    getAllRegs();
  }, [sortBy, order]);
  return (
    <div>
      <div className="bg-white fixed w-full left-0 pl-20 z-10">
        <div className="flex items-end mx-3 mb-2 justify-between">
          <div className="flex flex-col justify-end">
            <h2 className="text-xl font-bold my-2">Synchronicity</h2>
            <div className="flex items-end">
              <button
                className="btn btn-neutral mr-2"
                onClick={() => {
                  exportToExcel("synchronicity24");
                }}
              >
                Save as Excel
              </button>
              <label className=" flex items-center gap-2 font-semibold mx-2">
                Entries: {currentCount}&nbsp;/&nbsp;{totalCount}
                <button
                  onClick={() => {
                    getNext();
                  }}
                  className="btn"
                >
                  Show More
                </button>
              </label>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-end">
            <label className="form-control w-full max-w-40 mx-2">
              <div className="label">
                <span className="label-text">Sort by</span>
              </div>
              <select
                id="orderBy"
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                  setSearchBy(e.target.value);
                }}
                className="select select-bordered w-full max-w-40"
              >
                <option value={"name"}>By Name</option>
                <option value={"event"}>By Event</option>
                <option value={"city"}>By City</option>
              </select>
            </label>
            <label className="form-control w-full max-w-40 mx-2">
              <div className="label">
                <span className="label-text">Order by</span>
              </div>
              <select
                value={order}
                onChange={(e) => {
                  setOrder(e.target.value);
                }}
                className="select select-bordered w-full max-w-40"
              >
                <option value={"asc"}>Ascending</option>
                <option value={"desc"}>Descending</option>
              </select>
            </label>

            <label className="form-control w-full max-w-52 mx-2">
              <div className="label">
                <span className="label-text">Search</span>
              </div>
              <input
                id="search"
                value={searchText}
                type="text"
                className="input input-bordered w-full max-w-xs"
                onChange={(e) => setSearchText(e.target.value.toUpperCase())}
                placeholder={`Search by ${searchBy}`}
              />
            </label>
            <button
              onClick={() => {
                getAllRegs();
              }}
              className="btn"
            >
              Search
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="overflow-x-auto pt-28">
          <table className="table">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Event</th>
                <th>email</th>
                <th>Phone</th>
                <th>Alt. Phone</th>
                <th>City</th>
                <th>Songs</th>
                <th>More Songs</th>
                <th>Facebook</th>
                <th>Member1</th>
                <th>Member2</th>
                <th>Member3</th>
                <th>Member4</th>
                <th>Member5</th>
                <th>Member6</th>
                <th>Other</th>
              </tr>
            </thead>
            <tbody>
              {regs.map((reg, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{reg.data.name}</td>
                    <td>{reg.data.event}</td>
                    <td>
                      <a href={`mailto:${reg.data.email}`}>{reg.data.email}</a>
                    </td>
                    <td>{reg.data.phone}</td>
                    <td>{reg.data.altphone}</td>
                    <td>{reg.data.city}</td>
                    <td>{reg.data.songs}</td>
                    <td>{reg.data.moresongs}</td>
                    <td className="link">
                      <a target="_blank" href={reg.data.fblink}>
                        {reg.data.fblink}
                      </a>
                    </td>
                    <td>{reg.data.member1}</td>
                    <td>{reg.data.member2}</td>
                    <td>{reg.data.member3}</td>
                    <td>{reg.data.member4}</td>
                    <td>{reg.data.member5}</td>
                    <td>{reg.data.member6}</td>
                    <td className="link">
                      <a target="_blank" href={reg.data.otherlinks}>
                        {reg.data.otherlinks}
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Synchronicity;
