import { TypeAnimation } from "react-type-animation";
const Home = () => {
  return (
    <div className="relative right-10 top-52 flex justify-center items-center">
      <TypeAnimation
        sequence={[
          "Welcome to Dev...",
          2000,
          "The New Backend of Antaragni",
          2000,
        ]}
        // wrapper="span"
        cursor={true}
        repeat={Infinity}
        speed={1}
        className="text-5xl font-bold"
      />
    </div>
  );
};
export default Home;
