import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { AiOutlineTeam, AiOutlineInstagram } from "react-icons/ai";
import { BiBookContent, BiBell, BiTrip } from "react-icons/bi";
import { BsFillPersonLinesFill, BsPersonVcardFill } from "react-icons/bs";
import { MdOutlineEmojiEvents } from "react-icons/md";
import { useAuth } from "../hooks";
import {
  TbLayoutSidebarLeftExpand,
  TbLayoutSidebarRightExpand,
} from "react-icons/tb";
import { useState } from "react";
const SideMenu = () => {
  const auth = useAuth();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const accessLevels = auth.user.details.accessLevels;
  const iconSize = "1.3em";

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="bg-white fixed left-0 top-16 z-20">
      <Sidebar
        rootStyles={{
          height: "calc(100vh - 60px)",
          width: "100px",
        }}
        className="bg-white"
        collapsed={isCollapsed}
      >
        <Menu closeOnClick={true}>
          <MenuItem
            component={<Link to="/dashboard" />}
            icon={<LuLayoutDashboard size={iconSize} />}
          >
            Dashboard
          </MenuItem>
          <MenuItem
            component={<Link to="/dashboard/team-management" />}
            icon={<AiOutlineTeam size={iconSize} />}
            disabled={!accessLevels.includes("admin")}
          >
            Team Management
          </MenuItem>
          <SubMenu
            icon={<BiBookContent size={iconSize} />}
            disabled={
              !accessLevels.includes("admin") && !accessLevels.includes("web")
            }
            label="Content"
          >
            <MenuItem component={<Link to="/dashboard/webcontents" />}>
              Web Contents
            </MenuItem>
            <MenuItem component={<Link to="/dashboard/webcontentsnew" />}>
              New Web Contents
            </MenuItem>
            <MenuItem component={<Link to="/dashboard/schedule" />}>
              Schedule
            </MenuItem>
          </SubMenu>
          <SubMenu
            icon={<BsFillPersonLinesFill size={iconSize} />}
            label="Campus Ambassador"
            disabled={
              !accessLevels.includes("admin") && !accessLevels.includes("ca")
            }
          >
            <MenuItem component={<Link to="/dashboard/ca" />}>
              Campus Ambassadors
            </MenuItem>
            <MenuItem component={<Link to="/dashboard/ca/tasks" />}>
              Tasks
            </MenuItem>
            <MenuItem component={<Link to="/dashboard/ca/submissions" />}>
              Submissions
            </MenuItem>
            <MenuItem component={<Link to="/dashboard/ca/ideas" />}>
              Verify Ideas
            </MenuItem>
          </SubMenu>
          <MenuItem
            icon={<BsPersonVcardFill size={iconSize} />}
            component={<Link to="/dashboard/associates" />}
            disabled={
              !accessLevels.includes("admin") &&
              !accessLevels.includes("associates")
            }
          >
            Associates
          </MenuItem>
          <SubMenu
            icon={<MdOutlineEmojiEvents size={iconSize} />}
            label="Events"
            disabled={
              !accessLevels.includes("admin") &&
              !accessLevels.includes("events")
            }
          >
            <MenuItem
              component={<Link to="/dashboard/events/users/registrations" />}
            >
              Registrations
            </MenuItem>
            <MenuItem component={<Link to="/dashboard/events/teams" />}>
              Teams
            </MenuItem>
          </SubMenu>
          <SubMenu icon={<BiTrip size={iconSize} />} label="Road Trips">
            <MenuItem
              component={<Link to="/dashboard/synchro" />}
              disabled={
                !accessLevels.includes("admin") &&
                !accessLevels.includes("synchro")
              }
            >
              Synchronicity
            </MenuItem>
            <MenuItem
              component={<Link to="/dashboard/djwar" />}
              disabled={
                !accessLevels.includes("admin") &&
                !accessLevels.includes("djwar")
              }
            >
              DJ War
            </MenuItem>
            <MenuItem
              component={<Link to="/dashboard/junoon" />}
              disabled={
                !accessLevels.includes("admin") &&
                !accessLevels.includes("junoon")
              }
            >
              Junoon
            </MenuItem>
            <MenuItem
              component={<Link to="/dashboard/comickaun" />}
              disabled={
                !accessLevels.includes("admin") &&
                !accessLevels.includes("comickaun")
              }
            >
              Comickaun
            </MenuItem>
            <SubMenu
              label="BUG"
              disabled={
                !accessLevels.includes("admin") && !accessLevels.includes("bug")
              }
            >
              <MenuItem component={<Link to="/dashboard/beatboxing" />}>
                Beatboxing
              </MenuItem>
              <MenuItem component={<Link to="/dashboard/rap" />}>
                Battle Rap
              </MenuItem>
            </SubMenu>
            {/* <MenuItem component={<Link to="/dashboard/nationals" />}>
            Nationals
          </MenuItem> */}
          </SubMenu>
        </Menu>
        <div className="flex justify-center relative top-20">
          {isCollapsed ? (
            <TbLayoutSidebarLeftExpand
              size={iconSize}
              onClick={toggleCollapse}
            />
          ) : (
            <TbLayoutSidebarRightExpand
              size={iconSize}
              onClick={toggleCollapse}
            />
          )}
        </div>
      </Sidebar>
    </div>
  );
};

export default SideMenu;
