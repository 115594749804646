import { useState, useEffect } from "react";
import { getAllDocs, updateData } from "../config/firestore";
import { Loader, ShowToast } from "../components";
import { AiOutlineClose } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import accessLevels from "../data/accessLevels.json";
const TeamManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [levelName, setLevelName] = useState("");
  const getAllUsers = async () => {
    setLoading(true);
    let data = await getAllDocs("teamManagement");
    if (data != null) {
      // console.log(data);
      setUsers(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  const approveAccount = async (user, value) => {
    await updateData("teamManagement", user.uid, {
      approved: value,
    });
    getAllUsers();
    ShowToast(`${value ? "Approved" : "Denied"}`);
  };

  const revokeLevel = async (user, level) => {
    await updateData("teamManagement", user.uid, {
      accessLevels: user.data.accessLevels.filter((value) => {
        return value != level;
      }),
    });
    getAllUsers();
    ShowToast("Level Revoked");
  };

  const readLevelName = (event) => {
    setLevelName(event.target.value);
  };

  const assignLevel = async (user) => {
    let newLevels = [...user.data.accessLevels, levelName];
    setLevelName("");
    await updateData("teamManagement", user.uid, {
      accessLevels: newLevels,
    });
    getAllUsers();
    ShowToast("Level Assigneed");
  };

  const acceptLevel = async (user, level) => {
    let newLevels = [...user.data.accessLevels, level];
    await updateData("teamManagement", user.uid, {
      requestedLevels: user.data.requestedLevels.filter((value) => {
        return value != level;
      }),
    });
    await updateData("teamManagement", user.uid, {
      accessLevels: newLevels,
    });
    getAllUsers();
    ShowToast("Level Approved");
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="bg-white fixed w-full left-0 pl-20 z-10">
        <h2 className="text-xl font-bold mx-3 mb-3">Team Manager</h2>
      </div>
      <div className="overflow-x-auto pt-10">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>email</th>
              <th>Approved</th>
              <th>Access Levels</th>
              <th>Requested Levels</th>
              <th>Assign Levels</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr>
                  <td>{user.data.displayName}</td>
                  <td>{user.data.email}</td>
                  <td>
                    <input
                      className="checkbox"
                      checked={user.data.approved}
                      type="checkbox"
                      onChange={(event) => {
                        // console.log(event.target.checked);
                        approveAccount(user, event.target.checked);
                      }}
                    />
                  </td>
                  <td>
                    <ul>
                      {user.data.accessLevels.map((level) => {
                        return (
                          <li className="btn m-2">
                            {level}
                            <AiOutlineClose
                              onClick={() => {
                                revokeLevel(user, level);
                              }}
                              className="hover:text-red-400"
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                  <td>
                    <ul>
                      {user.data.requestedLevels.map((level) => {
                        return (
                          <li className="btn m-2">
                            {level}
                            {
                              <BsCheckLg
                                onClick={() => {
                                  acceptLevel(user, level);
                                }}
                                className="hover:text-green-400"
                              />
                            }
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                  <td className="flex justify-center items-center">
                    <select
                      className="select select-bordered w-full max-w-xs min-w-36"
                      onChange={readLevelName}
                    >
                      <option disabled selected>
                        Access Level
                      </option>
                      {accessLevels
                        .filter((l) => !user.data.accessLevels.includes(l))
                        .map((level) => (
                          <option value={level}>{level}</option>
                        ))}
                    </select>
                    <button
                      className="btn btn-neutral ml-2"
                      onClick={() => assignLevel(user)}
                    >
                      Assign
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamManagement;
