import { utils, writeFile, read } from "xlsx";
import { getAllDocs } from "../config/firestore";
import { jsDateToExcelDate } from "./";
import { Timestamp } from "firebase/firestore";
// Export to Excel from Collection Name
export const exportToExcel = async (collectionName) => {
  let data = await getAllDocs(collectionName);
  const newArray = [];
  for (let i = 0; i < data.length; i++) {
    newArray.push(data[i].data);
  }
  downloadExcel(collectionName, newArray);
};
// Export to Excel from Array
export const downloadExcel = (fileName, array) => {
  const ws = utils.json_to_sheet(array, { dateNF: "dd-mm-yyyy hh:mm" });
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  writeFile(wb, `${fileName}.xlsx`);
};

// Export to JSON
export const downloadJson = (fileName, data) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = `${fileName}.json`;
  link.click();
};

// Import from Excel
export const importExcel = (e, setExcelJson) => {
  e.preventDefault();
  if (e.target.files) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      setExcelJson(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  }
};

// Import from Json
export const importJson = (e, setJson) => {
  e.preventDefault();
  if (e.target.files) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      let json = JSON.parse(data);
      let newJSON = [];
      let firstEntry = json[0];
      let heads = Object.keys(firstEntry);
      for (let entry of json) {
        let newEntry = {};
        for (let head of heads) {
          if (head === "Id") {
            newEntry[head] = entry[head];
          } else if (
            entry[head].type === "STRING" ||
            entry[head].type === "NUMBER" ||
            entry[head].type === "BOOLEAN" ||
            entry[head].type === "MD" ||
            entry[head].type === "JSON" ||
            entry[head].type === "FILE"
          ) {
            newEntry[head] = entry[head];
          } else if (entry[head].type === "TIMESTAMP") {
            let { seconds, nanoseconds } = entry[head].content;
            let timestamp = new Timestamp(seconds, nanoseconds);
            newEntry[head] = { ...entry[head], content: timestamp };
          }
        }
        newJSON.push(newEntry);
      }
      setJson(newJSON);
    };
    reader.readAsText(e.target.files[0]);
  }
};

// Convert JSON to required Excel format
export const convertJsonToExcelJson = (fileName, json) => {
  let excelJson = [];
  let firstEntry = json[0];
  let heads = Object.keys(firstEntry);
  for (let entry of json) {
    let newEntry = {};
    for (let head of heads) {
      if (head === "Id") {
        newEntry[head] = entry[head];
      } else {
        if (
          entry[head].type === "STRING" ||
          entry[head].type === "NUMBER" ||
          entry[head].type === "BOOLEAN" ||
          entry[head].type === "MD"
        ) {
          newEntry[head] = entry[head].content;
        } else if (entry[head].type === "JSON" || entry[head].type === "FILE") {
          newEntry[head] = JSON.stringify(entry[head].content);
        } else if (entry[head].type === "TIMESTAMP") {
          newEntry[head] = entry[head].content.toDate();
        }
      }
    }
    excelJson.push(newEntry);
  }
  downloadExcel(fileName, excelJson);
};

// Convert Excel JSON to required json format
export const convertExcelJsonToJson = (excelJson) => {
  let json = [];
  let firstEntry = excelJson[0];
  let heads = Object.keys(firstEntry);
  for (let entry of excelJson) {
    let newEntry = {};
    for (let head of heads) {
      if (head === "Id") {
        newEntry[head] = entry[head];
      } else {
        newEntry[head] = {
          type: "",
          content: "",
        };
        newEntry[head]["content"] = entry[head];
        if (typeof entry[head] === "string") {
          newEntry[head]["type"] = "STRING";
        } else if (typeof entry[head] === "number") {
          newEntry[head]["type"] = "NUMBER";
        } else if (typeof entry[head] === "boolean") {
          newEntry[head]["type"] = "BOOLEAN";
        }
      }
    }
    json.push(newEntry);
  }
  return json;
};
