import { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { BiEdit } from "react-icons/bi";
import {
  getDocumentsCount,
  getFirstData,
  getNextData,
  updateData,
  deleteData,
  queryData,
} from "../config/firestore";
import { Loader, ShowAlert, ShowToast } from "../components";
import { exportToExcel } from "../utils";
const EventsTeams = () => {
  const [regs, setRegs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [sortBy, setSortBy] = useState("teamName");
  const [order, setOrder] = useState("asc");
  const [searchBy, setSearchBy] = useState("teamName");
  const [searchText, setSearchText] = useState("");
  const [lastDoc, setLastDoc] = useState("");

  const getAllRegs = async () => {
    setLoading(true);
    let { last, data } = await getFirstData(
      "eventsTeams24",
      sortBy,
      order,
      searchBy,
      searchText
    );
    let count = await getDocumentsCount("eventsTeams24");
    if (data != null) {
      setRegs(data);
      setCurrentCount(data.length);
      setTotalCount(count);
      setLastDoc(last);
    }
    setLoading(false);
  };

  const getNext = async () => {
    if (lastDoc != null) {
      let { last, data } = await getNextData(
        "eventsTeams24",
        sortBy,
        order,
        lastDoc,
        searchBy,
        searchText
      );
      if (data != null) {
        setRegs([...regs, ...data]);
        setLastDoc(last);
        setCurrentCount(currentCount + data.length);
      }
    }
  };

  const contentStyle = {
    background: "white",
    borderRadius: "16px",
    boxShadow: " 0 4px 30px rgba(0, 0, 0, 0.1)",
    border: "2px solid var(--primaryColor)",
  };

  const updateTeam = (teamData) => {
    let idx = regs.findIndex((x) => x.uid === teamData.uid);
    let newRegs = [...regs];
    newRegs[idx] = teamData;
    setRegs(newRegs);
  };

  useEffect(() => {
    getAllRegs();
  }, [sortBy, order]);
  return (
    <div>
      <div className="bg-white fixed w-full left-0 pl-20 z-10">
        <div className="flex items-end mx-3 mb-2 justify-between">
          <div className="flex flex-col justify-end">
            <h2 className="text-xl font-bold my-2">Events Teams</h2>
            <div className="flex items-end">
              <button
                className="btn btn-neutral mr-2"
                onClick={() => {
                  exportToExcel("eventsTeams24");
                }}
              >
                Save as Excel
              </button>
              <label className=" flex items-center gap-2 font-semibold mx-2">
                Entries: {currentCount}&nbsp;/&nbsp;{totalCount}
                <button
                  onClick={() => {
                    getNext();
                  }}
                  className="btn"
                >
                  Show More
                </button>
              </label>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-end">
            <label className="form-control w-full max-w-40 mx-2">
              <div className="label">
                <span className="label-text">Sort by</span>
              </div>
              <select
                id="orderBy"
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                  setSearchBy(e.target.value);
                }}
                className="select select-bordered w-full max-w-40"
              >
                <option value={"teamName"}>By Team Name</option>
                <option value={"college"}>By College</option>
                <option value={"collegeCity"}>By College City</option>
              </select>
            </label>
            <label className="form-control w-full max-w-40 mx-2">
              <div className="label">
                <span className="label-text">Order by</span>
              </div>
              <select
                value={order}
                onChange={(e) => {
                  setOrder(e.target.value);
                }}
                className="select select-bordered w-full max-w-40"
              >
                <option value={"asc"}>Ascending</option>
                <option value={"desc"}>Descending</option>
              </select>
            </label>

            <label className="form-control w-full max-w-52 mx-2">
              <div className="label">
                <span className="label-text">Search</span>
              </div>
              <input
                id="search"
                value={searchText}
                type="text"
                className="input input-bordered w-full max-w-xs"
                onChange={(e) => setSearchText(e.target.value.toUpperCase())}
                placeholder={`Search by ${searchBy}`}
              />
            </label>
            <button
              onClick={() => {
                getAllRegs();
              }}
              className="btn"
            >
              Search
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="overflow-x-auto pt-28">
          <table className="table">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Team Id</th>
                <th>Team Name</th>
                <th>College</th>
                <th>College City</th>
                <th>Club Name</th>
                <th>Club Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {regs.map((reg, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{reg.uid}</td>
                    <td>{reg.data.teamName}</td>
                    <td className="overflow">
                      <div>{reg.data.college}</div>
                    </td>

                    <td>{reg.data.collegeCity}</td>
                    <td>{reg.data.clubName}</td>
                    <td>{reg.data.clubEmail}</td>
                    <td>
                      <div style={{ display: "flex" }}>
                        <Popup
                          trigger={
                            <span>
                              <button className="btn btn-sm mx-1">View</button>
                            </span>
                          }
                          modal
                          {...{ contentStyle }}
                        >
                          <EditModal reg={reg} updateTeam={updateTeam} />
                        </Popup>
                        <button
                          onClick={async (e) => {
                            ShowAlert(
                              "Delete Team",
                              "Are you sure you want to delete this team !",
                              async () => {
                                await deleteData("eventsTeams24", reg.uid);
                                await getAllRegs();
                              }
                            );
                          }}
                          className="btn btn-sm btn-neutral mx-1"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const EditModal = ({ reg, updateTeam }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [regs, setRegs] = useState([]);
  const [teamId, setTeamId] = useState(reg.data.teamId);
  const [teamName, setTeamName] = useState(reg.data.teamName);
  const [college, setCollege] = useState(reg.data.college);
  const [collegeCity, setCollegeCity] = useState(reg.data.collegeCity);
  const [clubName, setClubName] = useState(
    reg.data.clubName !== undefined ? reg.data.clubName : ""
  );
  const [clubEmail, setClubEmail] = useState(
    reg.data.clubEmail !== undefined ? reg.data.clubEmail : ""
  );
  const [isEditing, setIsEditing] = useState(false);
  const fetchTeamMembers = async () => {
    try {
      let data = await queryData("eventsUsers2024", "teamId", teamId);
      if (data != null) {
        setTeamMembers(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchRegistrations = async () => {
    try {
      let data = await queryData("eventsRegistrations", "teamId", teamId);
      if (data != null) {
        setRegs(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const saveDetails = async (uid) => {
    try {
      if (
        college !== null &&
        college !== "" &&
        collegeCity !== "" &&
        teamId !== null &&
        teamId !== "" &&
        teamName !== null &&
        teamName !== ""
      ) {
        let teamData = {
          teamName: teamName,
          teamId: teamId,
          college: college,
          collegeCity: collegeCity,
          clubName: clubName,
          clubEmail: clubEmail,
        };
        await updateData("eventsTeams24", uid, teamData);
        await fetchTeamMembers();
        await fetchRegistrations();
        updateTeam({ uid: uid, data: teamData });
        setIsEditing(false);
        ShowToast("Team Info Updated Successfully");
      } else {
        ShowToast("Please Fill all the required Details");
      }
    } catch (e) {
      ShowToast("Something went wrong, Please try again later");
    }
  };

  const resetTeamData = () => {
    setTeamName(reg.data.teamName);
    setCollege(reg.data.college);
    setCollegeCity(reg.data.collegeCity);
    setClubName(reg.data.clubName !== undefined ? reg.data.clubName : "");
    setClubEmail(reg.data.clubEmail !== undefined ? reg.data.clubEmail : "");
  };

  useEffect(() => {
    fetchTeamMembers();
    fetchRegistrations();
  }, []);

  return (
    <div className="p-3 flex flex-col h-96 overflow-auto">
      <span className="flex justify-between">
        <span>Team Details</span>
        {!isEditing && (
          <BiEdit
            size={20}
            onClick={() => {
              setIsEditing(true);
            }}
          />
        )}
      </span>
      <div className="flex flex-wrap justify-evenly items-center">
        <label className="form-control w-full max-w-64">
          <div className="label">
            <span className="label-text">Team Id</span>
          </div>
          <input
            placeholder="Team Id"
            type="text"
            readOnly
            className="input input-bordered w-full max-w-xs"
            value={teamId}
          ></input>
        </label>

        <label className="form-control w-full max-w-64">
          <div className="label">
            <span className="label-text">Team Name</span>
          </div>
          <input
            placeholder="Team Name"
            type="text"
            readOnly={!isEditing}
            className="input input-bordered w-full max-w-xs"
            value={teamName}
            onChange={(e) => {
              setTeamName(e.target.value.toUpperCase());
            }}
          />
        </label>
        <label className="form-control w-full max-w-64">
          <div className="label">
            <span className="label-text">Club Name</span>
          </div>
          <input
            placeholder="Club Name"
            type="text"
            readOnly={!isEditing}
            className="input input-bordered w-full max-w-xs"
            value={clubName}
            onChange={(e) => {
              setClubName(e.target.value.toUpperCase());
            }}
          ></input>
        </label>
        <label className="form-control w-full max-w-64">
          <div className="label">
            <span className="label-text">Club Email Id</span>
          </div>
          <input
            placeholder="Club Email Id"
            type="email"
            readOnly={!isEditing}
            className="input input-bordered w-full max-w-xs"
            value={clubEmail}
            onChange={(e) => {
              setClubEmail(e.target.value);
            }}
          ></input>
        </label>
        <label className="form-control w-full max-w-64">
          <div className="label">
            <span className="label-text">College</span>
          </div>
          <input
            placeholder="College"
            type="text"
            readOnly={!isEditing}
            className="input input-bordered w-full max-w-xs"
            value={college}
            onChange={(e) => {
              setCollege(e.target.value.toUpperCase());
            }}
          ></input>
        </label>
        <label className="form-control w-full max-w-64">
          <div className="label">
            <span className="label-text">City of College</span>
          </div>
          <input
            placeholder="City of College"
            type="text"
            readOnly={!isEditing}
            className="input input-bordered w-full max-w-xs"
            value={collegeCity}
            onChange={(e) => {
              setCollegeCity(e.target.value.toUpperCase());
            }}
          ></input>
        </label>
      </div>
      {isEditing && (
        <div className="w-full flex justify-center my-3 ">
          <button
            className="btn btn-neutral mx-2"
            onClick={(e) => {
              saveDetails(reg.uid);
            }}
          >
            Save
          </button>
          <button
            className="btn mx-2"
            onClick={() => {
              setIsEditing(false);
              resetTeamData();
            }}
          >
            Cancel
          </button>
        </div>
      )}

      <span className="my-3">Team Members</span>
      {/* <div className="flex flex-wrap justify-evenly items-start">
        {teamMembers.map((member, index) => {
          return (
            <div className="flex flex-col justify-center items-start border-2 rounded-lg p-2 m-2">
              <span className="">Sr.No.: {index + 1}</span>
              <span className="">Id: {member.data.id}</span>
              <span className="">Name: {member.data.name}</span>
            </div>
          );
        })}
      </div> */}
      <div className="">
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              <th></th>
              <th>Id</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {teamMembers.map((member, index) => (
              <tr>
                <th>{index + 1}</th>
                <td>{member.data.id}</td>
                <td>{member.data.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventsTeams;
