import { AuthContext } from "../providers";
import { useState, useEffect, useContext } from "react";
import { ShowToast } from "../components";

import {
  firebaseLogin,
  firebaseSignup,
  firebaseGetUser,
  firebaseLogout,
} from "../config/firebaseAuth";
import { addData, setData, getSingleDoc } from "../config/firestore";

export const useAuth = () => {
  return useContext(AuthContext);
};

export const useProvideAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getUser = async () => {
      setLoading(true);
      firebaseGetUser(setUser, setLoading);
    };
    getUser();
  }, []);

  const login = async (username, password) => {
    let email = username + "@ant.in";
    let user = await firebaseLogin(email, password);
    let details = {};
    if (user) {
      details = await getSingleDoc("teamManagement", user.uid);
      if (details.approved == false) {
        await firebaseLogout();
        setUser(null);
        ShowToast("Account Not Approved by Admin !");
        return;
      }
    }

    if (user) {
      ShowToast("Logged in successfully");
      setUser({ user, details });
    } else {
      ShowToast("Account not exists");
    }
    return;
  };

  const signup = async (name, username, password, confirmPassword) => {
    let email = username + "@ant.in";
    if (password !== confirmPassword) {
      ShowToast("Password & Confirm Password not match");
      return;
    }
    let user = await firebaseSignup(email, password);
    if (user) {
      await setData("teamManagement", user.uid, {
        email: email,
        username: username,
        displayName: name,
        accessLevels: ["member"],
        requestedLevels: [],
        uid: user.uid,
        approved: false,
      });
      ShowToast("Signed up successfully");
      await firebaseLogout();
      setUser(null);
    }
    return;
  };

  const logout = async () => {
    await firebaseLogout();
    setUser(null);
    ShowToast("Logged out successfully");
  };
  return {
    user,
    login,
    logout,
    signup,
    loading,
  };
};
