import { useState, useEffect } from "react";
import {
  getDocumentsCount,
  getFirstData,
  getNextData,
  getSingleDoc,
  updateData,
} from "../config/firestore";
import { Loader } from "../components";
import { exportToExcel } from "../utils";
const CASubmissions = () => {
  const [regs, setRegs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [sortBy, setSortBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [searchBy, setSearchBy] = useState("name");
  const [searchText, setSearchText] = useState("");
  const [lastDoc, setLastDoc] = useState("");

  const getAllRegs = async () => {
    setLoading(true);
    let { last, data } = await getFirstData(
      "CAsSubmissions24",
      sortBy,
      order,
      searchBy,
      searchText
    );
    let count = await getDocumentsCount("CAsSubmissions24");
    if (data != null) {
      setRegs(data);
      setCurrentCount(data.length);
      setTotalCount(count);
      setLastDoc(last);
    }
    setLoading(false);
  };

  const getNext = async () => {
    if (lastDoc != null) {
      let { last, data } = await getNextData(
        "CAsSubmissions24",
        sortBy,
        order,
        lastDoc,
        searchBy,
        searchText
      );
      if (data != null) {
        setRegs([...regs, ...data]);
        setLastDoc(last);
        setCurrentCount(currentCount + data.length);
      }
    }
  };

  const awardPoint = async (id, uid, point, prePoints) => {
    try {
      let user = await getSingleDoc("CAs24", uid);
      let points = user.points - prePoints;
      points += point;
      await updateData("CAs24", uid, { points: points });
      await updateData("CAsSubmissions24", id, { award: point });
      getAllRegs();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRegs();
  }, [sortBy, order]);
  return (
    <div>
      <div className="bg-white fixed w-full left-0 pl-20 z-10">
        <div className="flex items-end mx-3 mb-2 justify-between">
          <div className="flex flex-col justify-end">
            <h2 className="text-xl font-bold my-2">CA Submissions</h2>
            <div className="flex items-end">
              <button
                className="btn btn-neutral mr-2"
                onClick={() => {
                  exportToExcel("CAsSubmissions24");
                }}
              >
                Save as Excel
              </button>
              <label className=" flex items-center gap-2 font-semibold mx-2">
                Entries: {currentCount}&nbsp;/&nbsp;{totalCount}
                <button
                  onClick={() => {
                    getNext();
                  }}
                  className="btn"
                >
                  Show More
                </button>
              </label>
            </div>
          </div>
          <div className="flex flex-1 items-end justify-end">
            <label className="form-control w-full max-w-40 mx-2">
              <div className="label">
                <span className="label-text">Sort by</span>
              </div>
              <select
                id="orderBy"
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                  setSearchBy(e.target.value);
                }}
                className="select select-bordered w-full max-w-40"
              >
                <option value={"id"}>By CA Id</option>
                <option value={"name"}>By Name</option>
                <option value={"college"}>By College</option>
                <option value={"collegeCity"}>By College City</option>
                <option value={"taskId"}>By Task Id</option>
              </select>
            </label>
            <label className="form-control w-full max-w-40 mx-2">
              <div className="label">
                <span className="label-text">Order by</span>
              </div>
              <select
                value={order}
                onChange={(e) => {
                  setOrder(e.target.value);
                }}
                className="select select-bordered w-full max-w-40"
              >
                <option value={"asc"}>Ascending</option>
                <option value={"desc"}>Descending</option>
              </select>
            </label>

            <label className="form-control w-full max-w-52 mx-2">
              <div className="label">
                <span className="label-text">Search</span>
              </div>
              <input
                id="search"
                value={searchText}
                type="text"
                className="input input-bordered w-full max-w-xs"
                onChange={(e) => setSearchText(e.target.value.toUpperCase())}
                placeholder={`Search by ${searchBy}`}
              />
            </label>
            <button
              onClick={() => {
                getAllRegs();
              }}
              className="btn"
            >
              Search
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="overflow-x-auto pt-28 ">
          <table className="table">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>CA Id</th>
                <th>Name</th>
                <th>Email Id</th>
                <th>Phone</th>
                <th>College</th>
                <th>City of College</th>
                <th>Task Id</th>
                <th>Task</th>
                <th>Task Points</th>
                <th>Task Submission</th>
                <th>Award</th>
              </tr>
            </thead>
            <tbody>
              {regs.map((reg, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{reg.data.id}</td>
                    <td>{reg.data.name}</td>
                    <td>
                      <a href={`mailto:${reg.data.email}`}>{reg.data.email}</a>
                    </td>
                    <td>{reg.data.phone}</td>

                    <td className="overflow">
                      <div>{reg.data.college}</div>
                    </td>
                    <td>
                      <div>{reg.data.collegeCity}</div>
                    </td>
                    <td className="overflow">{reg.data.taskId}</td>
                    <td className="overflow">{reg.data.taskDesc}</td>
                    <td className="overflow">{reg.data.taskPoints}</td>
                    <td className="overflow">
                      <a href={reg.data.link} target="_blank">
                        {reg.data.link}
                      </a>
                    </td>
                    <td className="overflow">
                      <input
                        placeholder="Award"
                        type="number"
                        className="input input-bordered w-full max-w-xs min-w-40"
                        defaultValue={reg.data.award}
                        onKeyDown={async (event) => {
                          if (event.key == "Enter") {
                            await awardPoint(
                              reg.uid,
                              reg.data.uid,
                              parseInt(event.target.value),
                              parseInt(
                                reg.data.award != undefined ? reg.data.award : 0
                              )
                            );
                          }
                        }}
                      ></input>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CASubmissions;
