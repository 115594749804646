import docs from "../assets/icons/docs.png";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks";
const Navbar = () => {
  const auth = useAuth();
  return (
    <div className="navbar bg-base-100 h-12 fixed z-30">
      <div className="flex-1">
        <Link to="/" className="btn btn-ghost text-3xl font-bold ">
          Dev
        </Link>
      </div>

      <div className="flex-none">
        <ul className="menu menu-horizontal px-1">
          {auth.user != null && auth.user.details != null && (
            <li>
              <a
                href="https://docs.dev.antaragni.in"
                className="btn mx-1"
                target="_blank"
              >
                <img width={20} height={20} src={docs} />
              </a>
            </li>
          )}
          {auth.user == null || auth.user.details == null ? (
            <li>
              <Link className="btn btn-neutral mx-1" to="/login">
                Login
              </Link>
            </li>
          ) : (
            <>
              <li>
                <Link to="/dashboard/profile" className="btn mx-1">
                  {auth.user.details.displayName}
                </Link>
              </li>
              <li>
                <button className="btn btn-neutral mx-1" onClick={auth.logout}>
                  Logout
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
