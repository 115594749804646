import { useState, useEffect } from "react";
import { deleteData, getAllDocs, setData } from "../config/firestore";
import { Loader } from "../components";
import { Timestamp } from "firebase/firestore";
import { convertToDateTimeLocalString } from "../utils";

const TasksCA = () => {
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [desc, setDesc] = useState("");
  const [deadline, setDeadline] = useState("");
  const [points, setPoints] = useState();

  const getTasks = async () => {
    setLoading(true);
    let data = await getAllDocs("tasksCA");
    if (data != null) {
      setTasks(data.reverse());
    }
    setLoading(false);
  };

  useEffect(() => {
    getTasks();
  }, []);

  // Add Task
  const addUpdateTask = async (taskId) => {
    if (desc !== "" && deadline != null && points !== "") {
      let taskData = {
        desc: desc,
        deadline: Timestamp.fromDate(new Date(deadline)),
        points: points,
      };
      await setData("tasksCA", taskId, taskData);
      setDesc("");
      setDeadline("");
      setPoints("");
      getTasks();
    }
  };

  return (
    <div>
      <div className="bg-white fixed w-full left-0 pl-20 z-10">
        <div className="mx-3">
          <h2 className="text-xl font-bold my-2">Add New Task </h2>
          <div className="flex items-end">
            <textarea
              value={desc}
              placeholder="Description"
              onChange={(e) => {
                setDesc(e.target.value);
              }}
              className="input input-bordered w-full max-w-xs mr-1"
            ></textarea>
            <input
              type="datetime-local"
              placeholder="Deadline"
              value={deadline}
              onChange={(e) => {
                setDeadline(e.target.value);
              }}
              className="input input-bordered w-full max-w-xs mx-1"
            ></input>
            <input
              type="text"
              value={points}
              placeholder="Points"
              onChange={(e) => {
                setPoints(e.target.value);
              }}
              className="input input-bordered w-full max-w-xs mx-1"
            ></input>
            <button
              className="btn btn-neutral  mx-1"
              onClick={() => {
                addUpdateTask(Date.now().toString());
              }}
            >
              Add
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="overflow-x-auto pt-24 ">
          <table className="table">
            <thead>
              <tr>
                <th>Task Id</th>
                <th>Description</th>
                <th>Deadline</th>
                <th>Points</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task) => {
                return <TaskRow key={task.uid} task={task} getTasks={getTasks} />;
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default TasksCA;

// Row
const TaskRow = ({ task, getTasks }) => {
  const [editing, setediting] = useState(false);
  const [desc, setDesc] = useState(task.data.desc);
  const [deadline, setDeadline] = useState(
    task.data.deadline ? convertToDateTimeLocalString(task.data.deadline.toDate()) : ""
  );
  const [points, setPoints] = useState(task.data.points);

  const addUpdateTask = async (taskId) => {
    if (desc !== "" && deadline != null && points !== "") {
      let taskData = {
        desc: desc,
        deadline: Timestamp.fromDate(new Date(deadline)),
        points: points,
      };
      await setData("tasksCA", taskId, taskData);
      getTasks();
    }
  };

  return (
    <tr>
      <td>{task.uid}</td>
      <td>
        {editing ? (
          <textarea
            className="input input-bordered w-full max-w-xs mx-1"
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
            }}
          />
        ) : (
          task.data.desc
        )}
      </td>
      <td>
        {editing ? (
          <input
            className="input input-bordered w-full max-w-xs mx-1"
            type="datetime-local"
            placeholder="Deadline"
            value={deadline}
            onChange={(e) => {
              setDeadline(e.target.value);
            }}
          ></input>
        ) : (
          task.data.deadline
            ? `${task.data.deadline.toDate().toDateString()} ${task.data.deadline.toDate().toLocaleTimeString()}`
            : "No deadline"
        )}
      </td>
      <td>
        {editing ? (
          <input
            value={points}
            className="input input-bordered w-full max-w-xs mx-1"
            onChange={(e) => {
              setPoints(e.target.value);
            }}
          />
        ) : (
          task.data.points
        )}
      </td>
      <td style={{ width: "150px" }}>
        {editing ? (
          <>
            <button
              className="btn btn-sm m-1"
              onClick={() => {
                setediting(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm btn-neutral m-1"
              onClick={() => {
                addUpdateTask(task.uid);
              }}
            >
              Update
            </button>
          </>
        ) : (
          <div>
            <button
              className="btn btn-sm m-1"
              onClick={() => {
                setediting(true);
              }}
            >
              Edit
            </button>
            <button
              className="btn btn-sm btn-neutral m-1 "
              onClick={async () => {
                await deleteData("tasksCA", task.uid);
                getTasks();
              }}
            >
              Delete
            </button>
          </div>
        )}
      </td>
    </tr>
  );
};
