import styles from "../css/schedule.module.css";
import { useState, useEffect } from "react";
import {
  deleteData,
  getAllDocs,
  setData,
  updateData,
} from "../config/firestore";
import { deleteFile, uploadFile } from "../config/firebaseStorage";
import { Button, Loader, ShowToast } from "../components";
import { Timestamp } from "firebase/firestore";
const Schedule = () => {
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState([]);
  const [desc, setDesc] = useState("");
  const [day, setDay] = useState("");
  const [eventName, setEventName] = useState("");
  const [venue, setVenue] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [image, setImage] = useState("");
  const getSchedule = async () => {
    setLoading(true);
    let data = await getAllDocs("schedule");
    if (data != null) {
      setSchedule(
        data.sort((a, b) =>
          a.data.startTime.seconds > b.data.startTime.seconds ? 1 : -1
        )
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getSchedule();
  }, []);

  // Add Schedule

  const addSchedule = async (scheduleId) => {
    try {
      if (
        eventName !== "" &&
        day !== "" &&
        desc !== "" &&
        venue !== "" &&
        startTime !== null &&
        startTime !== "" &&
        endTime !== null &&
        endTime !== "" &&
        latitude !== "" &&
        longitude !== "" &&
        image !== null &&
        image !== ""
      ) {
        let path = `/schedule/${image.name}`;
        let url = await uploadFile(path, image);
        let scheduleData = {
          eventName: eventName,
          day: day,
          desc: desc,
          venue: venue,
          startTime: Timestamp.fromDate(new Date(startTime)),
          endTime: Timestamp.fromDate(new Date(endTime)),
          latitude: Number(latitude),
          longitude: Number(longitude),
          image: {
            ref: path,
            url,
          },
        };
        await setData("schedule", scheduleId, scheduleData);
        getSchedule();
        setEventName("");
        setDay("");
        setDesc("");
        setVenue("");
        setStartTime("");
        setEndTime("");
        setLatitude("");
        setLongitude("");
        setImage("");
      } else {
        ShowToast("Please Fill all the info");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.schedule}>
      <div className={styles.addSchedule}>
        <h4>Add New Schedule </h4>
        <textarea
          value={eventName}
          placeholder="Event Name"
          onChange={(e) => {
            setEventName(e.target.value);
          }}
        ></textarea>
        <textarea
          placeholder="Day"
          value={day}
          onChange={(e) => {
            setDay(e.target.value);
          }}
        ></textarea>
        <textarea
          value={desc}
          placeholder="Description"
          onChange={(e) => {
            setDesc(e.target.value);
          }}
        ></textarea>
        <textarea
          value={venue}
          placeholder="Venue"
          onChange={(e) => {
            setVenue(e.target.value);
          }}
        ></textarea>
        <input
          type="datetime-local"
          placeholder="Start Time"
          value={startTime}
          onChange={(e) => {
            setStartTime(e.target.value);
          }}
        ></input>
        <input
          type="datetime-local"
          placeholder="End Time"
          value={endTime}
          onChange={(e) => {
            setEndTime(e.target.value);
          }}
        ></input>
        <input
          type="number"
          value={latitude}
          placeholder="Latitude"
          onChange={(e) => {
            setLatitude(e.target.value);
          }}
        ></input>
        <input
          type="number"
          value={longitude}
          placeholder="Longitude"
          onChange={(e) => {
            setLongitude(e.target.value);
          }}
        ></input>
        <input
          type="file"
          id="input-file"
          onChange={async (event) => {
            let file = event.target.files[0];
            setImage(file);
          }}
        ></input>
        <Button
          text="Add"
          onClick={() => {
            addSchedule(Date.now().toString());
          }}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="tableWrapper">
          <table className="table">
            <thead>
              <tr>
                <th>Day</th>
                <th>Event Name</th>
                <th>Description</th>
                <th>Venue</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {schedule.map((scheduleRow) => {
                return (
                  <ScheduleRow
                    scheduleRow={scheduleRow}
                    getSchedule={getSchedule}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default Schedule;

// Row
const ScheduleRow = ({ scheduleRow, getSchedule }) => {
  const [editing, setEditing] = useState(false);
  const [desc, setDesc] = useState(scheduleRow.data.desc);
  const [day, setDay] = useState(scheduleRow.data.day);
  const [eventName, setEventName] = useState(scheduleRow.data.eventName);
  const [venue, setVenue] = useState(scheduleRow.data.venue);
  const [startTime, setStartTime] = useState(
    convertToDateTimeLocalString(scheduleRow.data.startTime.toDate())
  );
  const [endTime, setEndTime] = useState(
    convertToDateTimeLocalString(scheduleRow.data.endTime.toDate())
  );
  const [longitude, setLongitude] = useState(scheduleRow.data.longitude);
  const [latitude, setLatitude] = useState(scheduleRow.data.latitude);
  const [image, setImage] = useState("");

  const updateSchedule = async (scheduleId) => {
    try {
      if (
        eventName !== "" &&
        desc !== "" &&
        day !== "" &&
        venue !== "" &&
        startTime !== null &&
        startTime !== "" &&
        endTime !== null &&
        endTime !== "" &&
        latitude !== "" &&
        longitude !== ""
      ) {
        let scheduleData = {};
        if (image != null && image !== "") {
          await deleteFile(scheduleRow.data.image.ref);
          let path = `/schedule/${image.name}`;
          let url = await uploadFile(path, image);
          scheduleData = {
            eventName: eventName,
            day: day,
            desc: desc,
            venue: venue,
            startTime: Timestamp.fromDate(new Date(startTime)),
            endTime: Timestamp.fromDate(new Date(endTime)),
            latitude: Number(latitude),
            longitude: Number(longitude),
            image: {
              ref: path,
              url,
            },
          };
        } else {
          scheduleData = {
            eventName: eventName,
            day: day,
            desc: desc,
            venue: venue,
            startTime: Timestamp.fromDate(new Date(startTime)),
            endTime: Timestamp.fromDate(new Date(endTime)),
            latitude: Number(latitude),
            longitude: Number(longitude),
          };
        }
        await updateData("schedule", scheduleId, scheduleData);
        getSchedule();
      } else {
        ShowToast("Please Fill all the info");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <tr>
      <td>
        {editing ? (
          <textarea
            value={day}
            onChange={(e) => {
              setDay(e.target.value);
            }}
          />
        ) : (
          scheduleRow.data.day
        )}
      </td>
      <td>
        {editing ? (
          <textarea
            value={eventName}
            onChange={(e) => {
              setEventName(e.target.value);
            }}
          />
        ) : (
          scheduleRow.data.eventName
        )}
      </td>
      <td>
        {editing ? (
          <textarea
            value={desc}
            onChange={(e) => {
              setDesc(e.target.value);
            }}
          />
        ) : (
          <div className={styles.desc}>{scheduleRow.data.desc}</div>
        )}
      </td>
      <td>
        {editing ? (
          <textarea
            value={venue}
            onChange={(e) => {
              setVenue(e.target.value);
            }}
          />
        ) : (
          scheduleRow.data.venue
        )}
      </td>
      <td>
        {editing ? (
          <input
            type="datetime-local"
            placeholder="Start Time"
            value={startTime}
            onChange={(e) => {
              setStartTime(e.target.value);
            }}
          ></input>
        ) : (
          `${scheduleRow.data.startTime
            .toDate()
            .toDateString()} ${scheduleRow.data.startTime
            .toDate()
            .toLocaleTimeString()}`
        )}
      </td>
      <td>
        {editing ? (
          <input
            type="datetime-local"
            placeholder="End Time"
            value={endTime}
            onChange={(e) => {
              setEndTime(e.target.value);
            }}
          ></input>
        ) : (
          `${scheduleRow.data.endTime
            .toDate()
            .toDateString()} ${scheduleRow.data.endTime
            .toDate()
            .toLocaleTimeString()}`
        )}
      </td>
      <td>
        {editing ? (
          <input
            value={latitude}
            type="number"
            onChange={(e) => {
              setLatitude(e.target.value);
            }}
          />
        ) : (
          scheduleRow.data.latitude
        )}
      </td>
      <td>
        {editing ? (
          <input
            value={longitude}
            type="number"
            onChange={(e) => {
              setLongitude(e.target.value);
            }}
          />
        ) : (
          scheduleRow.data.longitude
        )}
      </td>
      <td>
        {editing ? (
          <input
            type="file"
            onChange={(e) => {
              let file = e.target.files[0];
              setImage(file);
            }}
          />
        ) : scheduleRow.data.image.url === null ||
          scheduleRow.data.image.url === undefined ||
          scheduleRow.data.image.url === "" ||
          scheduleRow.data.image.url === " " ? (
          <span>No Image Found</span>
        ) : (
          <a href={scheduleRow.data.image.url} target="_blank">
            View
          </a>
        )}
      </td>
      <td style={{ width: "150px" }}>
        {editing ? (
          <>
            <Button
              text="Cancel"
              onClick={() => {
                setEditing(false);
              }}
            />
            <Button
              text="Update"
              onClick={() => {
                updateSchedule(scheduleRow.uid);
              }}
            />
          </>
        ) : (
          <div>
            <Button
              text="Edit"
              onClick={() => {
                setEditing(true);
              }}
            />
            <Button
              text="Delete"
              onClick={async () => {
                let prevImg = scheduleRow.data.image;
                if (prevImg != null && prevImg.ref != "") {
                  deleteFile(prevImg.ref);
                }
                await deleteData("schedule", scheduleRow.uid);
                getSchedule();
              }}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

// Function to convert Date to local time

const convertToDateTimeLocalString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
