import { useAuth } from "../hooks";
import { Loader, ShowToast } from "../components";
import { useState } from "react";
import { updateData } from "../config/firestore";
import accessLevels from "../data/accessLevels.json";
import { FaUser } from "react-icons/fa";
const Profile = () => {
  const auth = useAuth();
  const [level, setLevel] = useState("");
  const readLevel = (event) => {
    setLevel(event.target.value);
  };

  const requestLevel = async (user) => {
    let newLevels = [...user.details.requestedLevels, level];
    setLevel("");
    await updateData("teamManagement", user.details.uid, {
      requestedLevels: newLevels,
    });
    ShowToast("Level Requested");
  };
  return auth.loading ? (
    <Loader />
  ) : (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-xl font-bold my-2 flex items-center">
        <FaUser size={25} /> User Profile
      </h2>

      <div className=" flex justify-evenly items-center flex-wrap w-2/3">
        <div className="w-1/2 p-3 min-w-52">
          <label className="form-control w-full max-w-xs">
            <div className="label">
              <span className="label-text font-semibold">Username</span>
            </div>
            <input
              type="text"
              placeholder="Username"
              readOnly
              value={auth.user.details.username}
              className="input input-bordered w-full max-w-xs"
            />
          </label>
          <label className="form-control w-full max-w-xs">
            <div className="label">
              <span className="label-text font-semibold">Name</span>
            </div>
            <input
              type="text"
              placeholder="Name"
              readOnly
              value={auth.user.details.displayName}
              className="input input-bordered w-full max-w-xs"
            />
          </label>
          <label className="form-control w-full max-w-xs">
            <div className="label">
              <span className="label-text font-semibold">Email</span>
            </div>
            <input
              type="text"
              placeholder="Email"
              readOnly
              value={auth.user.details.email}
              className="input input-bordered w-full max-w-xs"
            />
          </label>
        </div>
        <div className="w-1/2 p-3 min-w-52 ">
          <div className="flex flex-col items-start">
            <span className="font-semibold my-2"> Access Levels</span>
            <ul>
              {auth.user.details.accessLevels.map((level) => {
                return <li className="btn m-1">{level}</li>;
              })}
            </ul>
          </div>
          <div className="flex flex-col items-start my-2">
            <span className="font-semibold my-2">Request Level</span>
            <select
              className="select select-bordered w-full max-w-xs min-w-36 my-2"
              onChange={readLevel}
            >
              <option disabled selected>
                Access Level
              </option>
              {accessLevels
                .filter((l) => !auth.user.details.accessLevels.includes(l))
                .map((level) => (
                  <option value={level}>{level}</option>
                ))}
            </select>
            <button
              className="btn btn-neutral  my-2"
              onClick={() => requestLevel(auth.user)}
            >
              Request
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
