import { ShowAlert, Loader, ShowToast } from "../components";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline, MdDeleteOutline } from "react-icons/md";
import { useState, useEffect } from "react";
import { getAllDocs, deleteData, setData } from "../config/firestore";
import { useAuth } from "../hooks";
import { deleteFolder } from "../config/firebaseStorage";
const WebContentsNew = () => {
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState([]);
  const [newContent, setNewContent] = useState("");
  const auth = useAuth();
  const fetchContents = async () => {
    setLoading(true);
    let data = await getAllDocs("WebContentsNew");
    if (data != null) {
      setContents(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchContents();
  }, []);

  const deleteContent = async (contentName) => {
    await deleteData("WebContentsNew", contentName);
    fetchContents();
    ShowToast("Content Deleted Successfully");
  };

  const AddNewContent = async () => {
    if (newContent !== "") {
      await setData("WebContentsNew", newContent, { data: [] });
      setNewContent("");
      ShowToast("Content Created Successfully");
      fetchContents();
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="bg-white fixed w-full left-0 pl-20 z-10">
        <h2 className="text-lg font-bold mx-3">Add New Content</h2>
        <input
          type="text"
          placeholder="Content Name"
          value={newContent}
          onChange={(e) => {
            setNewContent(e.target.value);
          }}
          className="input input-bordered w-full max-w-xs ml-3 mr-2 my-3"
        />
        <button
          className="btn btn-neutral mx-2"
          onClick={() => {
            AddNewContent();
          }}
        >
          Add Content
        </button>
      </div>

      <div className="overflow-x-auto pt-24">
        <table className="table">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Content Name</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {contents.map((content, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{content.uid}</td>
                  <td className="flex justify-evenly">
                    <Link to={`/dashboard/webcontentsnew/${content.uid}`}>
                      <MdOutlineModeEditOutline size={30} />
                    </Link>
                  </td>
                  <td>
                    <div className="flex justify-evenly">
                      <MdDeleteOutline
                        size={30}
                        color={
                          !auth.user.details.accessLevels.includes("admin") &&
                          !auth.user.details.accessLevels.includes("web") &&
                          "text-gray-300"
                        }
                        onClick={() => {
                          if (
                            auth.user.details.accessLevels.includes("admin") ||
                            auth.user.details.accessLevels.includes("web")
                          ) {
                            ShowAlert(
                              "Delete Content",
                              "Are you sure you want to delete this content !",
                              async () => {
                                deleteFolder(`/${content.uid}/`);
                                deleteContent(content.uid);
                              }
                            );
                          }
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WebContentsNew;
