import styles from "../css/contentCard.module.css";
import { MdOutlineModeEditOutline, MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
const ContentCard = (props) => {
  const { content, isPermitted, deleteContent } = props;
  return (
    <div className={styles.contentCard}>
      <div className={styles.contentName}>{content.uid}</div>
      <div className={styles.actions}>
        <Link to={`/dashboard/webcontents/${content.uid}`}>
          <MdOutlineModeEditOutline size={30} color="var(--primaryColor)" />
        </Link>
        <MdDeleteOutline
          size={30}
          color={isPermitted ? "var(--primaryColor)" : "var(--descColor)"}
          onClick={() => {
            deleteContent();
          }}
        />
      </div>
    </div>
  );
};

export default ContentCard;
