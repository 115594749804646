import { ThreeCircles } from "react-loader-spinner";
const Loader = () => {
  const styles = {
    display: "flex",
    width: "100%",
    height: "85vh",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <div style={styles}>
      <ThreeCircles
        height="100"
        width="100"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor="oklch(var(--n))"
        innerCircleColor="oklch(var(--nc))"
        middleCircleColor="oklch(var(--n))"
      />
    </div>
  );
};

export default Loader;
