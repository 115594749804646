import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../hooks";
const Login = () => {
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const auth = useAuth();
  const readUsername = (event) => {
    setUsername(event.target.value);
  };

  const readPass = (event) => {
    setPass(event.target.value);
  };

  const login = async () => {
    await auth.login(username, pass);
  };
  return (
    <div className=" relative right-10 top-20 w-full  flex justify-center ">
      <div className="flex flex-col items-center w-2/4 relative min-w-64">
        <span className="text-xl font-bold my-3">Login</span>
        <label className="input input-bordered flex items-center gap-2 my-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
          </svg>
          <input
            type="text"
            className="grow"
            placeholder="Username"
            value={username}
            onChange={readUsername}
          />
        </label>
        <label className="input input-bordered flex items-center gap-2 my-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path
              fillRule="evenodd"
              d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
              clipRule="evenodd"
            />
          </svg>
          <input
            type="password"
            className="grow"
            placeholder="Password"
            value={pass}
            onChange={readPass}
          />
        </label>
        <div className="flex flex-col items-center my-1 w-full">
          <button className="btn btn-neutral my-2" text="Login" onClick={login}>
            Login
          </button>
          <span className="font-semibold text-center   ">
            Don't have an account?{" "}
            <Link className="font-bold " to="/signup">
              Signup
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};
export default Login;
