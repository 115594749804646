import styles from "../css/content.module.css";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Loader, Button, ShowAlert } from "../components";
import { getSingleDoc, updateData } from "../config/firestore";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { uploadFile, deleteFile } from "../config/firebaseStorage";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
const Content = () => {
  let { contentId } = useParams();
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [headers, setHeaders] = useState([]);
  const [columnName, setColumnName] = useState("");
  const [type, setType] = useState("string");

  const fetchContent = async () => {
    setLoading(true);
    let doc = await getSingleDoc("WebContents", contentId);
    if (doc) {
      setContent(doc.data);
      if (doc.data && doc.data.length > 0) {
        let entry = doc.data[0];
        let head = Object.keys(entry);
        let headers = [];
        for (let i = 0; i < head.length; i++) {
          let type = typeof entry[head[i]];
          if (type !== "string" && type !== "number" && type !== "boolean") {
            type = "image";
          }
          if (type === "string" && entry[head[i]].length >= 5) {
            if (entry[head[i]].substring(0, 5) === "@HTML") {
              type = "@HTML";
            }
          }
          headers.push({ type: type, name: head[i] });
        }
        setHeaders(headers);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchContent();
  }, []);

  const updateContent = (data) => {
    try {
      setContent(data);
      if (data && data.length > 0) {
        let entry = data[0];
        let head = Object.keys(entry);
        let headers = [];
        for (let i = 0; i < head.length; i++) {
          let type = typeof entry[head[i]];
          if (type !== "string" && type !== "number" && type !== "boolean") {
            type = "image";
          }
          if (type === "string" && entry[head[i]].length >= 5) {
            if (entry[head[i]].substring(0, 5) === "@HTML") {
              type = "@HTML";
            }
          }
          headers.push({ type: type, name: head[i] });
        }
        setHeaders(headers);
      }
    } catch (e) {
      console.log(e);
      ShowAlert("Error in Updating Content");
    }
  };

  const editContent = async (id, head, value) => {
    let data = [...content];
    data = data.map(function (object) {
      if (object.Id == id) {
        object[head] = value;
      }
      return object;
    });
    await updateData("WebContents", contentId, { data });
    updateContent(data);
  };

  const alphanumeric_unique = () => {
    return Math.random()
      .toString(36)
      .split("")
      .filter(function (value, index, self) {
        return self.indexOf(value) === index;
      })
      .join("")
      .substr(2, 8);
  };

  const insertRow = async () => {
    let id = alphanumeric_unique();
    let newObject = {};
    for (let i = 0; i < headers.length; i++) {
      newObject[headers[i].name] = initialField(headers[i].type);
    }
    newObject["Id"] = id;
    let data = [...content];
    data.push(newObject);
    await updateData("WebContents", contentId, { data: data });
    updateContent(data);
  };

  const insertColumn = async (name, type) => {
    if (name == "" || type == "") {
      console.log("Inside");
      return;
    }
    let data = [...content];
    for (let i = 0; i < data.length; i++) {
      data[i][name] = initialField(type);
    }
    await updateData("WebContents", contentId, { data: data });
    updateContent(data);
  };

  const initialField = (targetType) => {
    switch (targetType) {
      case "string":
        return "";
      case "number":
        return -1;
      case "boolean":
        return false;
      case "image":
        return { ref: "", url: "" };
      case "@HTML":
        return "@HTML";
      default:
        break;
    }
  };

  const deleteRow = async (id) => {
    let data = [...content];
    data = data.filter((e) => {
      return e.Id != id;
    });
    await updateData("WebContents", contentId, { data });
    updateContent(data);
  };

  const deleteColumn = async (head) => {
    let data = [...content];
    for (let i = 0; i < data.length; i++) {
      delete data[i][head];
    }
    await updateData("WebContents", contentId, { data: data });
    updateContent(data);
  };

  const contentStyle = {
    background: "white",
    borderRadius: "16px",
    boxShadow: " 0 4px 30px rgba(0, 0, 0, 0.1)",
    border: "2px solid var(--primaryColor)",
  };

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.content}>
      <div className={styles.newEntry}>
        <Button onClick={insertRow} text="Insert New Row" />
        <input
          type="text"
          className={styles.input}
          placeholder="Column Name"
          onChange={(e) => {
            setColumnName(e.target.value);
          }}
        ></input>
        <select
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
          className={styles.input}
        >
          <option value="string">Text</option>
          <option value="@HTML">HTML Text</option>
          <option value="number">Number</option>
          <option value="boolean">Boolean</option>
          <option value="image">Image</option>
        </select>
        <Button
          onClick={() => {
            insertColumn(columnName, type);
          }}
          text="Insert New Column"
        />
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Sr. No.</th>
              {headers.map((head) =>
                head.name == "Id" ? (
                  <></>
                ) : (
                  <th>
                    <div className={styles.multi}>
                      {head.name}
                      <MdDelete
                        size={18}
                        color="var(--primaryColor)"
                        onClick={() => {
                          ShowAlert(
                            "Delete Column",
                            "Are you sure you want to delete this column !",
                            () => {
                              deleteColumn(head.name);
                            }
                          );
                        }}
                      />
                    </div>
                  </th>
                )
              )}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {content.map((cont, index) => (
              <tr>
                <td>{index + 1}</td>
                {headers.map((head) =>
                  head.name == "Id" ? (
                    <></>
                  ) : (
                    <td>
                      {head.type == "boolean" ? (
                        <input
                          checked={cont[head.name]}
                          className={styles.check}
                          type="checkbox"
                          onChange={(event) => {
                            editContent(
                              cont.Id,
                              head.name,
                              event.target.checked
                            );
                          }}
                        ></input>
                      ) : head.type == "string" ? (
                        <InputField
                          type={"text"}
                          cont={cont}
                          head={head}
                          editContent={editContent}
                        />
                      ) : head.type == "number" ? (
                        <InputField
                          type={"number"}
                          cont={cont}
                          head={head}
                          editContent={editContent}
                        />
                      ) : head.type == "image" ? (
                        <div className={styles.img}>
                          {cont[head.name].url === null ||
                          cont[head.name].url === undefined ||
                          cont[head.name].url === "" ||
                          cont[head.name].url === " " ? (
                            <span style={{ color: "red" }}>No Image Found</span>
                          ) : (
                            <a href={cont[head.name].url} target="_blank">
                              View
                            </a>
                          )}
                          {/* <img src={cont[head.name].url} /> */}
                          <input
                            type="file"
                            id="input-file"
                            onChange={async (event) => {
                              let file = event.target.files[0];
                              let path = `/${contentId}/${file.name}`;
                              let url = await uploadFile(
                                path,
                                event.target.files[0]
                              );
                              let prevImg = cont[head.name];
                              editContent(cont.Id, head.name, {
                                ref: path,
                                url,
                              });
                              if (prevImg != null && prevImg.ref != "") {
                                deleteFile(prevImg.ref);
                              }
                            }}
                          ></input>
                        </div>
                      ) : head.type === "@HTML" ? (
                        <Popup
                          trigger={
                            <span>
                              <Button text="Text Editor" />
                            </span>
                          }
                          modal
                          {...{ contentStyle }}
                        >
                          <TextEditor
                            content={cont}
                            head={head}
                            editContent={editContent}
                          />
                        </Popup>
                      ) : (
                        <></>
                      )}
                    </td>
                  )
                )}
                <td>
                  <div className={styles.actions}>
                    <AiOutlineArrowUp
                      size={30}
                      color="var(--primaryColor)"
                      onClick={async () => {
                        let newContent = [...content];
                        let index = newContent.findIndex((e) => {
                          return e.Id === cont.Id;
                        });
                        let preIndex = index - 1;
                        if (preIndex >= 0) {
                          let preValue = newContent[preIndex];
                          newContent[preIndex] = newContent[index];
                          newContent[index] = preValue;
                        }
                        await updateData("WebContents", contentId, {
                          data: newContent,
                        });
                        console.log(newContent);
                        setContent(newContent);
                      }}
                    />
                    <AiOutlineArrowDown
                      size={30}
                      color="var(--primaryColor)"
                      onClick={async () => {
                        let newContent = [...content];
                        let index = newContent.findIndex((e) => {
                          return e.Id === cont.Id;
                        });
                        let postIndex = index + 1;
                        if (postIndex < newContent.length) {
                          let postValue = newContent[postIndex];
                          newContent[postIndex] = newContent[index];
                          newContent[index] = postValue;
                        }
                        await updateData("WebContents", contentId, {
                          data: newContent,
                        });
                        console.log(newContent);
                        setContent(newContent);
                      }}
                    />
                    <MdDelete
                      size={30}
                      color="var(--primaryColor)"
                      onClick={() => {
                        ShowAlert(
                          "Delete Row",
                          "Are you sure you want to delete this row !",
                          async () => {
                            headers.map(async (head) => {
                              if (head.type === "image") {
                                let prevImg = cont[head.name];
                                if (prevImg != null && prevImg.ref != "") {
                                  deleteFile(prevImg.ref);
                                }
                              }
                            });
                            await deleteRow(cont.Id);
                          }
                        );
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const InputField = ({ type, cont, head, editContent }) => {
  const [initValue, setInitValue] = useState();

  useEffect(() => {
    setInitValue(cont[head.name]);
  });

  return type === "number" ? (
    <input
      type="number"
      defaultValue={initValue}
      onKeyDown={(event) => {
        if (event.key == "Enter") {
          editContent(cont.Id, head.name, Number(event.target.value));
        }
      }}
    ></input>
  ) : (
    <textarea
      defaultValue={initValue}
      onKeyDown={(event) => {
        if (event.key == "Enter") {
          editContent(cont.Id, head.name, event.target.value);
        }
      }}
    ></textarea>
  );
};

export default Content;

const TextEditor = ({ content, head, editContent }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    const contentBlock = htmlToDraft(content[head.name].substring(5));
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setValue(editorState);
  }, []);
  const onChange = (e) => {
    setValue(e);
  };

  const onSave = () => {
    let data = `@HTML${draftToHtml(convertToRaw(value.getCurrentContent()))}`;
    editContent(content.Id, head.name, data);
  };

  return (
    <div className={styles.textEditor}>
      <Editor
        editorClassName={styles.editor}
        editorState={value}
        onEditorStateChange={onChange}
      />
      <Button text="Save" onClick={onSave} />
    </div>
  );
};
