import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  Home,
  Login,
  Signup,
  Dashboard,
  TeamManagement,
  Profile,
  Junoon,
  WebContents,
  Content,
  ComicKaun,
  Synchronicity,
  Rap,
  Beatboxing,
  TasksCA,
  DJWar,
  EventsUsers,
  EventsTeams,
  EventsRegistrations,
  CA,
  CAIdeas,
  CASubmissions,
  EventsUsersRegistrations,
  Schedule,
  Associates,
  WebContentsNew,
  ContentNew,
} from "../page";
import { Navbar, SideMenu } from "./";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../hooks";
import { Loader } from "./";
function App() {
  const auth = useAuth();
  return auth.loading ? (
    <Loader />
  ) : (
    <div>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter>
        <Navbar />

        {auth.user && auth.user.details && auth.user.details.approved && (
          <SideMenu />
        )}
        <div className="w-full pt-16 pl-20">
          <Routes>
            <Route
              exact
              path="/"
              element={
                auth.user && auth.user.details && auth.user.details.approved ? (
                  <Navigate replace to="/dashboard" />
                ) : (
                  <Home />
                )
              }
            ></Route>
            <Route
              exact
              path="/login"
              element={
                auth.user && auth.user.details && auth.user.details.approved ? (
                  <Navigate replace to="/dashboard" />
                ) : (
                  <Login />
                )
              }
            ></Route>
            <Route
              exact
              path="/signup"
              element={
                auth.user && auth.user.details && auth.user.details.approved ? (
                  <Navigate replace to="/dashboard" />
                ) : (
                  <Signup />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard"
              element={
                auth.user && auth.user.details && auth.user.details.approved ? (
                  <Dashboard />
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/profile"
              element={
                auth.user && auth.user.details && auth.user.details.approved ? (
                  <Profile />
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/team-management"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ? (
                  <TeamManagement />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/junoon"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("junoon") ? (
                  <Junoon />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/comickaun"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("comickaun") ? (
                  <ComicKaun />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/synchro"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("synchro") ? (
                  <Synchronicity />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/rap"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("bug") ? (
                  <Rap />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/beatboxing"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("bug") ? (
                  <Beatboxing />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/djwar"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("djwar") ? (
                  <DJWar />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            {/* <Route
              exact
              path="/dashboard/events/users"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("events") ? (
                  <EventsUsers />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route> */}
            <Route
              exact
              path="/dashboard/events/teams"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("events") ? (
                  <EventsTeams />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            {/* <Route
              exact
              path="/dashboard/events/registrations"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("events") ? (
                  <EventsRegistrations />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route> */}
            <Route
              exact
              path="/dashboard/events/users/registrations"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("events") ? (
                  <EventsUsersRegistrations />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/webcontents"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("web") ? (
                  <WebContents />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/webcontentsnew"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("web") ? (
                  <WebContentsNew />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/schedule"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("web") ||
                  auth.user.details.accessLevels.includes("schedule") ? (
                  <Schedule />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/webcontentsnew/:contentId"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("web") ? (
                  <ContentNew />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/webcontents/:contentId"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("web") ? (
                  <Content />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/ca/tasks"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("ca") ? (
                  <TasksCA />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/ca"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("ca") ? (
                  <CA />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/ca/ideas"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("ca") ? (
                  <CAIdeas />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/ca/submissions"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("ca") ? (
                  <CASubmissions />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="/dashboard/associates"
              element={
                !auth.user ||
                !auth.user.details ||
                !auth.user.details.approved ? (
                  <Navigate replace to="/login" />
                ) : auth.user.details.accessLevels.includes("admin") ||
                  auth.user.details.accessLevels.includes("associates") ? (
                  <Associates />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            ></Route>
            <Route
              exact
              path="*"
              element={<h1>Error 404: Page Not Found</h1>}
            ></Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
